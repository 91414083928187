import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Spinner, useColorModeValue, Icon, Link } from '@chakra-ui/react';
import { FaUserTie, FaUsers, FaShoppingCart, FaChartLine } from 'react-icons/fa';
import { useData } from 'contexts/DataProvider'; // Assurez-vous que le chemin d'accès est correct
import axios from 'axios'; // Importation de axios
import LeaderTable from './leaderTable'; // Importation du composant LeaderTable
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

const AgencyCard = ({ agency }) => {
  const data = useData();
  const [salesCount, setSalesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'white');
  const [cardColor, setCardColor] = useState('');
  const [vendorNames, setVendorNames] = useState({});
  const [managerNames, setManagerNames] = useState({}); // Déclaration du state pour les noms des managers
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation(); // Utilisation du hook useLocation

  const handleOpenModal = () => {
    console.log("Sales codes passed to the modal:", agency.vendors); // Ajoutez ce log pour voir les sales codes
    setIsModalOpen(true);
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchManagerNames = async () => {
      try {
        const managersResponse = await axios.get("https://app.falconmarketing.fr:3001/api/vendors");
        const managersArray = managersResponse.data.records || [];
        const managers = managersArray.reduce((acc, current) => {
          acc[current.Id] = current.Name;
          return acc;
        }, {});
        setManagerNames(managers); // Mise à jour du state des noms des managers
      } catch (error) {
        console.error("Erreur lors du chargement des noms des managers:", error);
      }
    };

    fetchManagerNames();
  }, []);

  useEffect(() => {
    if (data && agency.vendors) {
      const filteredSales = data.filter(sale => agency.vendors.includes(sale.Seller__c));
      const count = filteredSales.length;
      setSalesCount(count);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    // Choix aléatoire d'une couleur parmi les couleurs prédéfinies
    const colors = ['purple.600', 'blue.800', 'orange.400'];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setCardColor(randomColor);
  }, [data, agency.vendors]);

  if (isLoading) {
    return (
      <Box padding="4" boxShadow="lg" bg={cardBg} borderRadius="lg" width="100%" textAlign="center">
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <Link
    as={ReactRouterLink}
    onClick={handleOpenModal}
    style={{ textDecoration: 'none' }}
    to={location.pathname} // Utilisez location.pathname au lieu de history.pathname
  >
    <Box bg={cardColor} color="white" borderRadius="lg" p={4} width="100%" boxShadow="2xl">
      <Text fontSize="2xl" fontWeight="bold" mb={4}>{agency.name}</Text>
      <Box bg={cardBg} borderRadius="lg" p={4} color={textColor} boxShadow="inner">
        <VStack spacing={4} align="left">
          <Text fontSize="lg"><Icon as={FaUserTie} mr={2} />Manager: {managerNames[agency.manager] || agency.manager}</Text>
          <Text fontSize="lg"><Icon as={FaUsers} mr={2} />Formateurs: {agency.teamLeaders?.length || 0}</Text>
          {console.log(agency.vendors)}
          <Text fontSize="lg"><Icon as={FaShoppingCart} mr={2} />Commerciaux: {agency.vendors?.length || 0}</Text>
          <Text fontSize="lg"><Icon as={FaChartLine} mr={2} />Ventes: {salesCount}</Text>
        </VStack>
      </Box>
      <LeaderTable
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        salesCodes={agency.vendors} // Passez les salesCode des commerciaux concernés
      />
    </Box>
    </Link>
  );
};

export default AgencyCard;
