import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "lib/firebase";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Box,
  Tooltip,
  Text,
  Select,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  Checkbox,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Nécessaire pour le style de la carte

const ListDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contactData, setContactData] = useState([]); // Stockez les données des contacts
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [docIdToDelete, setDocIdToDelete] = useState(null);

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiZG9rZ28iLCJhIjoiY2xzZDA4cHNsMGpiNjJpbWhobm03azNyeCJ9.rfLUyJVQL_JaaHkZV-tmhA";

  // useCallback pour optimiser le gestionnaire de clics
  const handleMarkerClick = useCallback((doc) => {
    setSelectedMarker(doc);
  }, []);

  const toast = useToast();
  const itemsPerPage = 10;
  const cancelRef = useRef();

  // Changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Définir fetchDocuments à ce niveau pour qu'elle soit accessible partout dans le composant
  const fetchDocuments = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const docsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Tri et autres opérations omises pour la brièveté
    setDocuments(docsArray);
  };

  useEffect(() => {
    fetchDocuments(); // Appel initial pour charger les documents
  }, []);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        let docsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Trier ici, une seule fois
        docsArray.sort((a, b) => {
          const aLastLogin = a.lastLogin ? a.lastLogin.seconds : 0;
          const bLastLogin = b.lastLogin ? b.lastLogin.seconds : 0;
          return bLastLogin - aLastLogin;
        });
  
        setDocuments(docsArray);
      } catch (error) {
        console.error("Erreur lors de la récupération des documents:", error);
      }
    };
  
    fetchDocuments();
  }, []);
  

  const addVendorToDocument = (vendorId) => {
    if (!selectedDoc.teamSalescode.includes(vendorId)) {
      const updatedTeamSalescode = [...selectedDoc.teamSalescode, vendorId];
      setSelectedDoc({ ...selectedDoc, teamSalescode: updatedTeamSalescode });

      // Affiche un toast de succès
      toast({
        title: "Vendeur ajouté",
        description: "Le vendeur a été ajouté avec succès aux teamSalescode.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Optionnel : Affiche un toast d'information si le vendeur est déjà dans la liste
      toast({
        title: "Vendeur existant",
        description: "Ce vendeur est déjà ajouté aux teamSalescode.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeVendor = (vendorId) => {
    const updatedTeamSalescode = selectedDoc.teamSalescode.filter(
      (code) => code !== vendorId
    );
    setSelectedDoc({ ...selectedDoc, teamSalescode: updatedTeamSalescode });
  };

  const fetchSalesforceContacts = async () => {
    try {
      const response = await fetch("https://app.falconmarketing.fr:3001/api/vendors");
      const data = await response.json();
      const sortedData = data.records.sort((a, b) => a.Name.localeCompare(b.Name));
      setContactData(sortedData);
      console.log("Contacts Salesforce récupérés avec succès :", data);
    } catch (error) {
      console.error("Erreur lors de la récupération des contacts Salesforce :", error);
    }
  };


  useEffect(() => {
    const numberOfPages = Math.ceil(filteredDocuments.length / itemsPerPage);
    setPageNumbers(Array.from({ length: numberOfPages }, (_, i) => i + 1));
  }, [filteredDocuments, itemsPerPage]);

  useEffect(() => {
    fetchSalesforceContacts();
  }, []); // Le tableau vide assure que cela s'exécute une seule fois au montage du composant

  // Mise à jour de useEffect pour le filtrage
  useEffect(() => {
    const filtered = documents.filter((doc) => {
      const emailMatches = searchTerm === "" || (doc.email && doc.email.toLowerCase().includes(searchTerm.toLowerCase()));
      const vendorNameMatches = getVendorNameById(doc.salesCode).toLowerCase().includes(searchTerm.toLowerCase());
      return emailMatches || vendorNameMatches;
    });
  
    const sortedFiltered = filtered.sort((a, b) => {
      const aVendorName = getVendorNameById(a.salesCode);
      const bVendorName = getVendorNameById(b.salesCode);
      return aVendorName.localeCompare(bVendorName);
    });
  
    setFilteredDocuments(sortedFiltered);
  }, [searchTerm, documents, contactData]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      let docsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Trier les documents par la dernière connexion, les plus récents en premier
      // Assurez-vous que `lastLogin` existe et utilisez `seconds` pour comparer
      docsArray.sort((a, b) => {
        const aLastLogin = a.lastLogin ? a.lastLogin.seconds : 0;
        const bLastLogin = b.lastLogin ? b.lastLogin.seconds : 0;
        return bLastLogin - aLastLogin; // Pour un tri décroissant
      });

      setDocuments(docsArray);
    };
    fetchDocuments();
  }, []);

  const findContactNameById = (contactId) => {
    const contact = contactData.find((contact) => contact.Id === contactId);
    return contact ? contact.Name : "ID inconnu";
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = documents.slice(indexOfFirstItem, indexOfLastItem);

  const editDocument = (doc) => {
    // Assurez-vous que teamSalescode est un tableau
    doc.teamSalescode = doc.teamSalescode || [];
    setSelectedDoc(doc);
    onOpen();
  };

  const getVendorNameById = (vendorId) => {
    const vendor = contactData.find((vendor) => vendor.Id === vendorId);
    return vendor ? vendor.Name.toLowerCase() : "";
  };

  // Fonction pour mettre à jour l'état local des documents
  const updateLocalDocument = (field, value) => {
    // Créez une copie du document sélectionné avec la modification
    const updatedDocument = { ...selectedDoc, [field]: value };
    setSelectedDoc(updatedDocument);
    // Mettez à jour l'état local du tableau (documents) avec le document modifié
    const updatedDocuments = documents.map((doc) =>
      doc.id === updatedDocument.id ? updatedDocument : doc
    );
    setDocuments(updatedDocuments);
    // Mettez à jour le document sélectionné
    setSelectedDoc(updatedDocument);
  };

  const handleSave = async () => {
    try {
      console.log("Saving", selectedDoc);
      // Update the document in Firestore
      const docRef = doc(db, "users", selectedDoc.id);
      const updatedFields = {
        ...selectedDoc, // inclut toutes les modifications apportées à selectedDoc, y compris teamSalescode
      };
      await updateDoc(docRef, updatedFields);

      // Affiche un toast de succès après la mise à jour
      toast({
        title: "Document mis à jour",
        description: "Les modifications ont été enregistrées avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose(); // Fermez la modale après la sauvegarde
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      // Affiche un toast d'erreur si la mise à jour échoue
      toast({
        title: "Échec de la mise à jour",
        description:
          "Une erreur est survenue lors de la sauvegarde des modifications.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (docId) => {
    // Afficher la fenêtre de confirmation
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce document ?"
    );
    if (isConfirmed) {
      try {
        await deleteDoc(doc(db, "users", docId));
        toast({
          title: "Document supprimé",
          description: "Le document a été supprimé avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose(); // Fermez la modale
        fetchDocuments(); // Rafraîchir la liste des documents
      } catch (error) {
        console.error("Erreur lors de la suppression du document :", error);
        toast({
          title: "Échec de la suppression",
          description:
            "Une erreur est survenue lors de la suppression du document.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const requestDelete = (docId) => {
    setIsDeleteConfirmationOpen(true);
    setDocIdToDelete(docId);
  };

  return (
    <>
      <Box my={2} mt={28}>
        <Input
          placeholder="Rechercher par nom de vendeur..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
        <Table variant="striped" colorScheme="blue">
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Vendeur</Th>
              <Th>Équipe</Th>
              <Th>Dernière connexion</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDocuments
              .slice(indexOfFirstItem, indexOfLastItem)
              .map((doc) => (
                <Tr key={doc.id}>
                  <Td>{doc.email}</Td>
                  <Td>
                    {doc.salesCode ? findContactNameById(doc.salesCode) : "N/A"}
                  </Td>
                  <Td>
                    {doc.teamSalescode &&
                    Array.isArray(doc.teamSalescode) &&
                    doc.teamSalescode.length > 0 ? (
                      <Tooltip
                        label={doc.teamSalescode
                          .map((id) => findContactNameById(id))
                          .join(", ")}
                        placement="bottom"
                        hasArrow
                        openDelay={100}
                      >
                        <Box cursor="pointer">
                          {doc.teamSalescode.length} membres
                        </Box>
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </Td>
                  <Td>
                    {doc.lastLogin
                      ? new Date(doc.lastLogin.seconds * 1000).toLocaleString()
                      : "Inconnue"}{" "}
                    {/* Affiche la date de dernière connexion */}
                  </Td>
                  <Td>
                    <Button
                      size="xs"
                      colorScheme="blue"
                      onClick={() => editDocument(doc)}
                    >
                      Modifier
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Stack
        direction="row"
        spacing={4}
        align="center"
        justifyContent="center"
        marginTop={4}
      >
        {pageNumbers.map((number) => (
          <Button
            key={number}
            colorScheme="blue"
            variant={currentPage === number ? "solid" : "outline"}
            onClick={() => paginate(number)}
          >
            {number}
          </Button>
        ))}
      </Stack>
      {selectedDoc && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modifier le profil</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  value={selectedDoc.email}
                  onChange={(e) => updateLocalDocument("email", e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
  <FormLabel>Vendeur</FormLabel>
  <Select
  placeholder="Sélectionnez un vendeur..."
  onChange={(e) => setSelectedDoc({ ...selectedDoc, salesCode: e.target.value })}
  value={selectedDoc.salesCode}
>
  {contactData
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .map((vendor) => (
      <option key={vendor.Id} value={vendor.Id}>
        {vendor.Name}
      </option>
    ))}
</Select>
</FormControl>

              <FormControl mt={4}>
                <FormLabel>Membres de l'équipe</FormLabel>
                <Box>
                  {selectedDoc.teamSalescode &&
                    selectedDoc.teamSalescode.map((code, index) => (
                      <Tag
                        size="lg"
                        key={index}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                        m={1}
                      >
                        <TagLabel>{findContactNameById(code)}</TagLabel>
                        <TagCloseButton onClick={() => removeVendor(code)} />
                      </Tag>
                    ))}
                </Box>
                <Select
                  mt={4}
                  placeholder="Sélectionnez un vendeur..."
                  onChange={(e) => setSelectedVendorId(e.target.value)}
                >
                  {contactData.map((vendor) => (
                    <option key={vendor.Id} value={vendor.Id}>
                      {vendor.Name}
                    </option>
                  ))}
                </Select>
                <Button
                  mt={2}
                  colorScheme="green"
                  onClick={() => addVendorToDocument(selectedVendorId)}
                >
                  Ajouter
                </Button>
              </FormControl>
              <Checkbox
                isChecked={selectedDoc.admin}
                onChange={(e) => updateLocalDocument("admin", e.target.checked)}
                mt={4}
                mr={2}
              >
                Admin
              </Checkbox>
              <Checkbox
                isChecked={selectedDoc.manager}
                onChange={(e) =>
                  updateLocalDocument("manager", e.target.checked)
                }
                mt={4}
                mr={2}
              >
                Manager
              </Checkbox>
              <Checkbox
                isChecked={selectedDoc.leader}
                onChange={(e) =>
                  updateLocalDocument("leader", e.target.checked)
                }
                mt={4}
              >
                Chef d'équipe
              </Checkbox>
              <FormControl mt={4}>
                <FormLabel>Dernière connexion</FormLabel>
                <Text>
                  {selectedDoc.lastLogin
                    ? new Date(
                        selectedDoc.lastLogin.seconds * 1000
                      ).toLocaleString()
                    : "Date de dernière connexion inconnue"}
                </Text>
              </FormControl>
              {/* Intégration de la carte */}
              <Box height="300px" width="100%" mt={2}>
                <Map
                  initialViewState={{
                    latitude: selectedDoc.geopoint?.latitude || 37.7577,
                    longitude: selectedDoc.geopoint?.longitude || -122.4376,
                    zoom: 14,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                  mapStyle="mapbox://styles/mapbox/standard"
                  mapboxAccessToken={MAPBOX_TOKEN}
                >
                  {selectedDoc.geopoint && (
                    <Marker
                      latitude={selectedDoc.geopoint.latitude}
                      longitude={selectedDoc.geopoint.longitude}
                      offsetLeft={-20}
                      offsetTop={-10}
                    >
                      <MdLocationOn size={30} color="red" />
                    </Marker>
                  )}
                </Map>
              </Box>
              <Button
                colorScheme="red"
                mt={2}
                mr={3}
                onClick={() => handleDelete(selectedDoc.id)}
              >
                Supprimer
              </Button>
              <AlertDialog
                isOpen={isDeleteConfirmationOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteConfirmationOpen(false)}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Supprimer le Document
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Êtes-vous sûr? Vous ne pouvez pas annuler cette action par
                      la suite.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button
                        onClick={() => setIsDeleteConfirmationOpen(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => handleDelete(docIdToDelete)}
                        ml={3}
                      >
                        Supprimer
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSave}>
                Sauvegarder
              </Button>
              <Button onClick={onClose}>Annuler</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ListDocuments;
