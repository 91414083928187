import {
    Box,
} from "@chakra-ui/react";
import ChestList from "./components/chestList";


export default function Chest() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <ChestList />
        </Box>
    )
}