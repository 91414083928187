import React from "react";
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
// Firebase auth instance
import firebaseAuth from "lib/firebase";
import { createUserDocument } from "lib/firebase";
import { db } from "lib/firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";

// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // Listener updates auth status when detects change
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setIsSignedIn(true);
        getUserData(user);
        // setUser(user);
      } else {
        setIsSignedIn(false);
        setUser(null);
      }
    });
  }, []);


  // Nouvelle fonction pour synchroniser avec PostgreSQL
  const synchronizeUser = async (firebaseUser) => {
    try {
      // Envoie une requête à votre backend avec l'UID Firebase de l'utilisateur
      // Votre backend va vérifier si cet utilisateur existe dans PostgreSQL
      // et l'ajouter si nécessaire
      const response = await fetch('https://app.falconmarketing.fr/synchronize-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: firebaseUser.uid, email: firebaseUser.email }),
      });
      const data = await response.json();
      console.log('User synchronized with PostgreSQL:', data);
    } catch (error) {
      console.error('Error synchronizing user:', error);
    }
  };

  // Modification des fonctions signIn et signUp
  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      await synchronizeUser(userCredential.user); // Synchronise après la connexion
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signUp = async (email, password, salesCode) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      await createUserDocument(userCredential.user, { salesCode });
      await synchronizeUser(userCredential.user); // Synchronise après l'inscription
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
    } catch (err) {
      console.log(err.message);
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };

  const createUserDocument = async (user, additionalData) => {
    const { email } = user;
    const { salesCode } = additionalData; // Ne prend pas en compte "isAdmin"

    try {
      await addDoc(collection(db, "users"), {
        money: 0,
        salesCode,
        email,
        createdAt: new Date(),
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserData = async (user) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      let isAdmin = false; // Valeur par défaut
      querySnapshot.forEach((doc) => {
        // Supposons que votre document utilisateur a un champ 'isAdmin'
        isAdmin = doc.data().admin || false;
        // Mise à jour de l'état de l'utilisateur avec les données et le statut d'admin
        setUser({ ...user, profileData: doc.data(), isAdmin });
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  
  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        signIn,
        signUp,
        signOut,
        googleSignIn,
        getUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};