import React, { createContext, useContext, useEffect, useState } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app as firebaseApp } from "lib/firebase";

export const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

const messaging = getMessaging(firebaseApp);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);

  // Demande manuelle de permission pour les notifications
  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const currentToken = await getToken(messaging, {
          vapidKey: "BO12gm0zq7wuWzATEBEQavzqDW7a_1afAgZhtfDMJO7TcsrEZbc8Z-wj2ltw3hjynCNM8wDSCvVLhhRvepUthNc"
        });
        setToken(currentToken);
      } else {
        console.log("Permission de notification refusée.");
      }
    } catch (error) {
      console.error('Erreur lors de la demande de permission:', error);
      setError(error);
    }
  };

  // Écoute des messages reçus
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message reçu: ", payload);
      setNotification(payload);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    notification,
    requestPermission,
    token,
    error
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
