'use client'

import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Stack,
  Image,
  Link,
} from '@chakra-ui/react'

const IMAGE =
  'https://png.pngtree.com/png-clipart/20221211/ourmid/pngtree-old-rusty-closed-treasure-chest-side-view-transparent-png-image_6520038.png'

export default function ChestCard({chestName, onContentOpen}) {
  return (
    <Center py={12}>
        <Link onClick={onContentOpen}>
        <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}>
            <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
                transition: 'all .3s ease',
                content: '""',
                w: 'full',
                h: 'full',
                pos: 'absolute',
                top: 5,
                left: 0,
                backgroundImage: `url(${IMAGE})`,
                filter: 'blur(15px)',
                zIndex: -1,
            }}
            _groupHover={{
                _after: {
                filter: 'blur(20px)',
                },
            }}>
            <Image
                rounded={'lg'}
                height={230}
                width={282}
                objectFit={'cover'}
                src={IMAGE}
                alt="#"
            />
            </Box>
            <Stack pt={10} align={'center'}>
            <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
                {chestName}
            </Heading>
            </Stack>
        </Box>
        </Link>
    </Center>
  )
}