import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
    fetchItems
} from "../../items/services/itemService"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    useDisclosure,
    Image,
    Flex,
} from "@chakra-ui/react";
import { nFormatter } from "components/utils/utils";

import Coin from "../../../../assets/img/dashboards/coin.png"
import Chest from "../../../../assets/img/dashboards/chest.png"
import AddModal from "./addModal";
import DeleteModal from "./deleteModal";
import ModifyModal from "./modifyModal";

// Fonction pour afficher la liste des items
export default function ItemList() {
    // État local pour stocker la liste des items
    const [documents, setDocuments] = useState([]);
    // État local pour gérer la pagination
    const [currentPage, setCurrentPage] = useState(1);
    // État local pour stocker l'ID du item à supprimer
    const [deletingItemId, setDeletingItemId] = useState(null);
    // State pour le gerer la selection d'un item
    const [selectedDoc, setSelectedDoc] = useState(null);


    // Hooks de gestion de l'ouverture et de la fermeture des modales
    const {
        isOpen: isModifyOpen,
        onOpen: onModifyOpen,
        onClose: onModifyClose
    } = useDisclosure();
    const {
        isOpen: isAddOpen,
        onOpen: onAddOpen,
        onClose: onAddClose
    } = useDisclosure();
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();

    // Constantes pour la pagination
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageCount = Math.ceil(documents.length / itemsPerPage);

    // Fonction pour gérer le changement de page
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    // Fonction pour récupérer la liste des items depuis Firestore
    const fetchDocuments = async () => {
        try {
            const items = await fetchItems();
            setDocuments(items);
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    };

    // Fonction pour ouvrir la modal de confirmation de suppression d'un item
    const openDeleteConfirmation = (itemId) => {
        setDeletingItemId(itemId);
        onDeleteOpen();
    };

    // Fonction pour ouvrir la modal de modification d'un item
    const editDocument = (doc) => {
        setSelectedDoc(doc);
        onModifyOpen();
    };

    // Effet pour récupérer la liste des items lors du premier rendu
    useEffect(() => {
        fetchDocuments()
    }, []);

    // Fonction pour afficher l'icône de récompense en fonction du type
    const getIcon = (rewardType) => {
        if (rewardType === "falcoins") return <Image src={Coin} width={6} height={6} />
        if (rewardType === "coffre") return <Image src={Chest} width={6} height={6} />
        else return (" " + rewardType)
    }

    // Rendu du composant
    return (
        <>
            {/* Bouton pour ajouter un nouveau item */}
            <Box my={2} mt={6}>
                <Button
                    colorScheme="green"
                    onClick={onAddOpen}
                >
                    Ajouter un item
                </Button>
            </Box>
            {/* Tableau pour afficher la liste des items */}
            <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
                <Table variant="striped" colorScheme="blue">
                <Thead>
                    <Tr>
                        <Th>Image</Th>
                        <Th>Nom</Th>
                        <Th>Quantité</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {documents
                    .slice(indexOfFirstItem, indexOfLastItem)
                    .map((doc) => (
                        <Tr key={doc.id}>
                        <Td><img style={{width: "100px"}} src={doc.image} /></Td>
                        <Td>{doc.name}</Td>
                        <Td>{nFormatter(doc.quantity, 2)}</Td>
                        <Td>
                            {/* Boutons pour modifier et supprimer un item */}
                            <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => editDocument(doc)}
                                mr={2}
                            >
                                Modifier
                            </Button>
                            <Button
                                size="xs"
                                colorScheme="red"
                                onClick={() => openDeleteConfirmation(doc.id)}
                            >
                                Supprimer
                            </Button>
                        </Td>
                        </Tr>
                    ))}
                </Tbody>
                </Table>
            </Box>

            {/* Pagination */}
            <Box mt={6}>
                <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </Box>

            <AddModal
                isOpen={isAddOpen}
                onClose={onAddClose}
                updateDocuments={fetchDocuments}
            />

            <DeleteModal
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                itemId={deletingItemId}
                updateDocuments={fetchDocuments}
            />

            {selectedDoc && (
                <ModifyModal
                    isOpen={isModifyOpen}
                    onClose={onModifyClose}
                    selectedDoc={selectedDoc}
                    setSelectedDoc={setSelectedDoc}
                    documents={documents}
                    setDocuments={setDocuments}
                />
            )}
        </>
    )
}
