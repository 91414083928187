import React from 'react'
import { nFormatter } from 'components/utils/utils';
import ReactSelect, { components } from "react-select";
import { Text, Flex } from '@chakra-ui/react';

const CustomSelect = ({ items, formData, setFormData }) => {
    const options = items.map((item) => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        image: item.image
    }));

    const { Option } = components;

    const ItemOption = (props) => (
        <Option {...props}>
            <div style={{display: "flex"}}>
                <img src={props.data.image} alt={props.data.name} style={{ width: 100, marginRight: 10 }} />
                <p>{nFormatter(props.data.quantity, 2)} {props.data.name}</p>
            </div>
        </Option>
    );

    return (
        <>
            <ReactSelect
                name="item"
                options={options}
                components={{ Option: ItemOption }}
                onChange={(e) => setFormData({ ...formData, rewardItem: e })}
            />

            {formData.rewardItem && (
                <>
                    <Text mt={2} mb={1}>Item sélectionnée :</Text>
                    <Flex alignItems="center" gap={5}>
                        <Text><strong>{nFormatter(formData.rewardItem.quantity, 2)} {formData.rewardItem.name}</strong></Text>
                        <img src={formData.rewardItem.image} alt="Selected Image" style={{ width: 100 }} />
                    </Flex>
                </>
            )}

            {formData.item && (
                <>
                    <Text mt={2} mb={1}>Item sélectionnée :</Text>
                    <Flex alignItems="center" gap={5}>
                        <Text><strong>{nFormatter(formData.item.quantity, 2)} {formData.item.name}</strong></Text>
                        <img src={formData.item.image} alt="Selected Image" style={{ width: 100 }} />
                    </Flex>
                </>
            )}
        </>
    );
}

export default CustomSelect