import React, { useContext } from 'react';
import { Box, Spinner, Flex, Heading, Text, useColorModeValue, Icon, Grid, useMediaQuery } from "@chakra-ui/react";
import { FaFileContract, FaMobileAlt, FaCheckCircle, FaSpinner, FaClipboardList } from 'react-icons/fa';
import { AuthContext } from "contexts/AuthContext";
import { useData } from 'contexts/DataProvider';

const ContratsValides = () => {
    const { user } = useContext(AuthContext);
    const data = useData();
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
    const hoverBgColor = useColorModeValue("gray.100", "gray.700");
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const startOfMonth = new Date(currentYear, currentMonth - 1, 1).getTime();
    const endOfMonth = new Date(currentYear, currentMonth, 0).getTime();

    const contratsValides = data?.filter(record => {
        if (record.ConnectionStatus__c === 'RaccordOK' && record.CreationDate__c && record.FamilyOffer__c === 'PREMIUM') {
            const recordDate = new Date(record.CreationDate__c).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    const contratsPremium = data?.filter(record => {
        if (record.FamilyOffer__c === 'PREMIUM' && record.CreationDate__c) {
            const recordDate = new Date(record.CreationDate__c).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    const contratsMobile = data?.filter(record => {
        if (record.OfferName__c.includes('Mobile') && record.CreationDate__c && record.Status__c === 'Validated') {
            const recordDate = new Date(record.CreationDate__c).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    const contratsEnCours = data?.filter(record => {
        if (record.ConnectionStatus__c === 'EnCours' && record.CreationDate__c) {
            const recordDate = new Date(record.CreationDate__c).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    const contratsTHD = data?.filter(record => {
        if (record.Status__c === 'Validated' && record.CreationDate__c) {
            const recordDate = new Date(record.CreationDate__c).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    const contratsDuMois = data?.filter(record => {
        if (record.CreatedDate) {
            const recordDate = new Date(record.CreatedDate).getTime();
            return recordDate >= startOfMonth && recordDate <= endOfMonth;
        }
        return false;
    }).length || 0;

    if (user && user.profileData && (user.profileData.leader || user.profileData.admin || user.profileData.manager)) {
        return null;
    }

    if (!data) {
        return <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />;
    }

    return (
        <Flex direction="column" align="center" mt={8}>
            <Heading size="lg" mb={6} align="center">
                <Icon as={FaClipboardList} mr={2} />
                Votre mois de production
            </Heading>
            <Grid
                templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
                gap={6}
            >
                <Box
                    bg={bgColor}
                    color={textColor}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    textAlign="center"
                    _hover={{ bg: hoverBgColor }}
                >
                    <Icon as={FaFileContract} boxSize={6} color="green.500" mb={2} />
                    <Heading size="md" mb={2}>Ventes brutes</Heading>
                    <Text fontSize="3xl" fontWeight="bold">{contratsDuMois}</Text>
                </Box>

                <Box
                    bg={bgColor}
                    color={textColor}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    textAlign="center"
                    _hover={{ bg: hoverBgColor }}
                >
                    <Icon as={FaCheckCircle} boxSize={6} color="blue.500" mb={2} />
                    <Heading size="md" mb={2}>Ventes validés Box</Heading>
                    <Text fontSize="3xl" fontWeight="bold">{contratsTHD}</Text>
                </Box>

                <Box
                    bg={bgColor}
                    color={textColor}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    textAlign="center"
                    _hover={{ bg: hoverBgColor }}
                >
                    <Icon as={FaCheckCircle} boxSize={6} color="green.500" mb={2} />
                    <Heading size="md" mb={2}>Raccordés</Heading>
                    <Text fontSize="3xl" fontWeight="bold">{contratsValides}</Text>
                </Box>

                <Box
                    bg={bgColor}
                    color={textColor}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    textAlign="center"
                    _hover={{ bg: hoverBgColor }}
                >
                    <Icon as={FaSpinner} boxSize={6} color="orange.500" mb={2} />
                    <Heading size="md" mb={2}>En cours</Heading>
                    <Text fontSize="3xl" fontWeight="bold">{contratsEnCours}</Text>
                </Box>

                <Box
                    bg={bgColor}
                    color={textColor}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    textAlign="center"
                    _hover={{ bg: hoverBgColor }}
                >
                    <Icon as={FaMobileAlt} boxSize={6} color="purple.500" mb={2} />
                    <Heading size="md" mb={2}>Mobiles validés</Heading>
                    <Text fontSize="3xl" fontWeight="bold">{contratsMobile}</Text>
                </Box>
            </Grid>
        </Flex>
    );
};

export default ContratsValides;