import React, { useState, useEffect } from 'react';
import { default as ReactSelect, components } from 'react-select';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    NumberInput,
    NumberInputField,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    Text
} from "@chakra-ui/react";
import { addItem } from "../services/itemService";
import axios from "axios";

export default function AddModal({ isOpen, onClose, updateDocuments }) {
    const toast = useToast();

    const [imageChoice, setImageChoice] = useState("existing"); // 'new' pour nouvelle image, 'existing' pour image existante

    const [formData, setFormData] = useState({
        name: "",
        quantity: 0,
        image: null,
    });

    const [imageLinks, setImageLinks] = useState([]);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState(null);

    function CustomSelect({ imageLinks }) {
        const options = imageLinks.map((image) => ({
            value: image.url,
            label: image.name,
            image: image.url
        }));
    
        const { Option } = components;
        const ImageOption = (props) => (
            <Option {...props}>
                <div style={{ display: "flex" }}>
                    <img src={props.data.image} alt={props.data.label} style={{ width: 100, marginRight: 10 }} />
                    {props.data.label}
                </div>
            </Option>
        );
    
        return (
            <>
                <ReactSelect
                    name="image"
                    options={options}
                    components={{ Option: ImageOption }}
                    onChange={(e) => setFormData({ ...formData, image: e.value })}
                />

                {formData.image && (
                    <>
                        <Text mt={2} mb={1}>Image sélectionnée :</Text>
                        <img src={formData.image} alt="Selected Image" style={{ width: 200 }} />
                    </>
                )}
            </>
        );
    }

    useEffect(() => {
        const url = 'http://82.180.155.225:3002/images';
  
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Mise à jour de l'état imageLinks avec les données récupérées
                setImageLinks(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        if (name === "image" && files) {
            const file = files[0];
            if (file.size > 2 * 1024 * 1024) { // Limite de 2MB
                setError('La taille de l\'image doit être inférieure à 2MB');
                setPreview(null);

                setFormData({ ...formData, image: null });
            } else {
                setError(null);
                setFormData({ ...formData, image: file });
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreview(reader.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    };

    const handleSave = async () => {
        try {
            let imageUrl = formData.image;
            if (imageChoice === "new" && formData.image) {
                let data = new FormData();
                data.append("image", formData.image);

               const res = await axios.post("http://82.180.155.225:3002/upload", data);
               if (res.status === 200) {
                imageUrl = "http://82.180.155.225:3002/images/" + res.data.filename;
               }
            }

            addItem(formData.name, formData.quantity, imageUrl);
            updateDocuments();

            // Réinitialiser le formulaire après avoir sauvegardé l'item
            setFormData({
                name: '',
                quantity: 0,
                image: '',
            });

            toast({
                title: "Succès",
                description: "L'Item a été créé !",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onClose();
        } catch (error) {
            console.error(error);
            toast({
                title: 'Erreur',
                description: "Une erreur est survenue lors de l'ajout de l'item. Veuillez réessayer.",
                status: 'error',
                isClosable: true,
                duration: 5000
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Ajouter un Item</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Nom</FormLabel>
                        <Input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Quantité</FormLabel>
                        <NumberInput value={formData.quantity} min={0} onChange={(value) => setFormData({ ...formData, quantity: value })}>
                            <NumberInputField
                                name="quantity"
                                onChange={handleChange}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Image</FormLabel>
                        <Select mb={4} name="imageChoice" onChange={(e) => setImageChoice(e.target.value)}>
                            <option value="existing">Sélectionner une image existante</option>
                            <option value="new">Télécharger une nouvelle image</option>
                        </Select>
                        {imageChoice === "new" ? (
                            <Input
                                name="image"
                                type="file"
                                accept="image/*"
                                onChange={handleChange}
                            />
                        ) : (
                            <CustomSelect imageLinks={imageLinks} />
                        )}
                    </FormControl>
                    {error && <Text color="red.500">{error}</Text>}
                    {preview && <img src={preview} alt="Aperçu" style={{ width: '100px', marginTop: '10px' }} />}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" mr={3} onClick={handleSave} isDisabled={error !== null} >
                        Sauvegarder
                    </Button>
                    <Button colorScheme="red" onClick={onClose}>Annuler</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
