import React, { useState, useEffect } from 'react';
import MapComponent from './mapcomponent';
import salesData from 'variables/data/salesData.json';

function SalesMap() {
  return (
    <div>
      <h1>Adresses de vente sur la carte</h1>
      <MapComponent salesData={salesData} />
    </div>
  );
}

export default SalesMap;