import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Text, useColorMode, Heading } from "@chakra-ui/react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useData } from "contexts/DataProvider"; // Importez useData pour accéder aux données globales

const ContratsJournaliers = () => {
  const jsonData = useData(); // Utilisez useData pour accéder aux données
  const { colorMode } = useColorMode();
  const [chartData, setChartData] = useState([]);
  const [period, setPeriod] = useState("Tous");

  const prepareChartData = (records, period) => {
    let filteredRecords = [...records];

    if (period !== "Tous") {
      const now = new Date();
      let periodStart = new Date();
      switch (period) {
        case "Semaine":
          periodStart.setDate(now.getDate() - 7);
          break;
        case "Mois":
          periodStart.setMonth(now.getMonth() - 1);
          break;
        case "Année":
          periodStart.setFullYear(now.getFullYear() - 1);
          break;
        default:
          break;
      }

      filteredRecords = records.filter((record) => new Date(record.CreatedDate) >= periodStart);
    }

    let obj = {};
    filteredRecords.sort((a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate)).forEach((record) => {
      const date = new Date(record.CreatedDate);
      const dateString = date.toLocaleDateString('fr-FR');
    
      if (obj[dateString]) {
        obj[dateString]++;
      } else {
        obj[dateString] = 1;
      }
    });

    const arr = Object.keys(obj).map((key) => ({
      date: key,
      count: obj[key],
    }));

    setChartData(arr);
  };

  useEffect(() => {
    if (jsonData) {
      prepareChartData(jsonData, period);
    }
  }, [jsonData, period]); // Ajoutez jsonData comme dépendance pour recalculer le graphique à chaque fois que les données ou la période changent

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  return (
    <Box p={5} boxShadow="xl" bg={colorMode === "dark" ? "gray.700" : "white"} borderRadius="md">
      <Heading size="lg" mb={5}>Vos ventes journalières</Heading>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData}>
          <Line type="monotone" dataKey="count" stroke="#1D3E5E" strokeWidth={3} dot={{ r: 5 }} />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" strokeOpacity={0.5} />
          <XAxis dataKey="date" tick={{ fontSize: 16, fontWeight: 'bold' }} />
          <YAxis tick={{ fontSize: 16, fontWeight: 'bold' }} />
          <Tooltip 
            contentStyle={{ fontSize: '16px', fontWeight: 'bold' }}
            itemStyle={{ color: '#1D3E5E' }} 
          />
        </LineChart>
      </ResponsiveContainer>
      <ButtonGroup mt={5}>
        {["Tous", "Semaine", "Mois", "Année"].map((p) => (
          <Button
            key={p}
            colorScheme={period === p ? "blue" : "gray"}
            onClick={() => handlePeriodChange(p)}
          >
            {p}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default ContratsJournaliers;
