import { useEffect, useState } from "react";
import {
  Flex,
  Spacer,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { fetchItems } from "views/admin/items/services/itemService";
import ReactSelect, { components } from "react-select";
import { nFormatter } from "components/utils/utils";
import { addChest } from "../services/chestsService";

export default function AddModal({ isOpen, onClose, updateDocuments }) {
  const toast = useToast();

  const [items, setItems] = useState([]);
  const [inputFields, setInputFields] = useState([{ item: null, drop: "100" }]);
  const [chestName, setChestName] = useState("");

  const [formData, setFormData] = useState({
    description: "",
    rewardItem: null,
    stepNumber: 0,
  });

  const addFields = () => {
    let newFields = { item: null, drop: "100" };
    setInputFields([...inputFields, newFields]);
  };

  const handleFormChange = (index, event) => {
    console.log(event);

    let data = [...inputFields];
    if (event.hasOwnProperty("target")) {
      data[index][event.target.name] = event.target.value;
    } else {
      data[index]["item"] = event;
    }
    setInputFields(data);

    console.log(data);
  };

  // Fonction pour sauvegarder le nouveau défi
  const handleSave = async () => {
    try {
      let itemsData = inputFields.map((field) => ({
        ...field,
        item: field.item.id,
      }));

      let finalData = {
        name: chestName,
        items: itemsData,
      };

      let response = await addChest(finalData);
      updateDocuments();
      
      toast({
        title: "Succès",
        description: "Le coffre a été ajouté avec succès.",
        status: "success",
        isClosable: true,
        duration: 5000,
      });

      onClose();
      setInputFields([{ item: null, drop: "100" }]);
      setChestName("");
    } catch (error) {
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de l'ajout du coffre. Veuillez réessayer.",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchItems().then((items) => {
      setItems(items);
    });
  }, []);

  const CustomSelect = ({ items, formData, setFormData, index }) => {
    const options = items.map((item) => ({
      id: item.id,
      name: item.name,
      quantity: item.quantity,
      image: item.image,
    }));

    const { Option } = components;

    const ItemOption = (props) => (
      <Option {...props}>
        <div style={{ display: "flex" }}>
          <img
            src={props.data.image}
            alt={props.data.name}
            style={{ width: 100, marginRight: 10 }}
          />
          <p>
            {nFormatter(props.data.quantity, 2)} {props.data.name}
          </p>
        </div>
      </Option>
    );

    return (
      <>
        <Box w="100%">
          <ReactSelect
            name="item"
            options={options}
            components={{ Option: ItemOption }}
            onChange={(e) => handleFormChange(index, e)}
          />
        </Box>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un coffre</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Formulaire pour ajouter un nouveau défi */}
          <FormControl>
            <FormLabel>Nom</FormLabel>
            <Input
              name="description"
              onChange={(e) => setChestName(e.target.value)}
            />
          </FormControl>
          {inputFields.map((input, index) => {
            return (
              <FormControl key={index} mt={4}>
                <Flex gap={3} alignItems="center">
                  <CustomSelect items={items} index={index} />
                  <input
                    defaultValue={100}
                    name="drop"
                    type="number"
                    min={0}
                    max={100}
                    step={0.01}
                    onChange={(e) => handleFormChange(index, e)}
                  />
                  <span>%</span>
                </Flex>
                {inputFields[index].item && (
                  <>
                    <Flex alignItems="center" gap={3} pl={1}>
                      <Text>
                        <strong>
                          {nFormatter(inputFields[index].item.quantity, 2)}{" "}
                          {inputFields[index].item.name}
                        </strong>
                      </Text>
                      <img
                        src={inputFields[index].item.image}
                        alt="Selected Image"
                        style={{ width: 40 }}
                      />
                    </Flex>
                  </>
                )}
              </FormControl>
            );
          })}
          <br />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={addFields}>
            Ajouter Item..
          </Button>
          <Spacer />
          {/* Bouton pour sauvegarder le coffre */}
          <Button colorScheme="green" mr={3} onClick={handleSave}>
            Sauvegarder
          </Button>
          {/* Bouton pour annuler l'ajout du défi */}
          <Button colorScheme="red" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
