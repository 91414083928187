import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
  Select,
  Text,
  VStack,
  useMediaQuery,
  FormErrorMessage,
} from '@chakra-ui/react';
import axios from 'axios';
import { AuthContext } from 'contexts/AuthContext';
import { db } from 'lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const AdresseForm = () => {
  const [numeroRue, setNumeroRue] = useState('');
  const [rue, setRue] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [vendeur, setVendeur] = useState('');
  const [adresses, setAdresses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const [isMobile] = useMediaQuery("(max-width: 48em)");

  useEffect(() => {
    const fetchVendeur = async () => {
      if (user && user.email) {
        try {
          const vendeursCollection = collection(db, 'users');
          const q = query(vendeursCollection, where('email', '==', user.email));
          const vendeursSnapshot = await getDocs(q);
          const vendeurData = vendeursSnapshot.docs.map(doc => doc.data().salesCode);
          if (vendeurData.length > 0) {
            setVendeur(vendeurData[0]);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du vendeur:", error);
          toast({
            title: 'Erreur',
            description: 'Erreur lors de la récupération du vendeur',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchVendeur();
  }, [user, toast]);

  useEffect(() => {
    if (vendeur) {
      fetchAdresses(vendeur);
      const interval = setInterval(() => {
        fetchAdresses(vendeur);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [vendeur]);

  const fetchAdresses = async (salesCode) => {
    try {
      const response = await axios.get(`https://app.falconmarketing.fr:3001/api/adresse/${salesCode}`);
      const sortedAdresses = response.data.sort((a, b) => b.id - a.id);
      setAdresses(sortedAdresses.slice(0, 10));
    } catch (error) {
      console.error('Erreur lors de la récupération des adresses:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les adresses',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
  
    // Validation pour le numéro de rue : ne doit pas se terminer par un espace
    if (numeroRue.endsWith(' ')) {
      newErrors.numeroRue = "Le numéro de rue ne doit pas se terminer par un espace.";
    }
  
    // Nouvelle validation pour le nom de la rue
    if (rue.endsWith(' ')) {
      newErrors.rue = "Le nom de la rue ne doit pas se terminer par un espace.";
    }
  
    // Validation pour le code postal
    if (!/^\d{5}$/.test(codePostal)) {
      newErrors.codePostal = "Le code postal doit être composé de 5 chiffres.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsSubmitting(true);
    try {
      await axios.post('https://app.falconmarketing.fr:3001/api/adresse', {
        numeroRue,
        rue,
        codePostal,
        salesCode: vendeur,
      });
      
      toast({
        title: 'Adresse soumise',
        description: 'L\'adresse a été soumise avec succès pour affectation.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNumeroRue('');
      setRue('');
      setCodePostal('');
      
      fetchAdresses(vendeur);
    } catch (error) {
      console.error("Erreur lors de la soumission de l'adresse:", error);
      toast({
        title: 'Erreur',
        description: error.response?.data || 'Une erreur est survenue',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box p={4} mt={isMobile ? '150px' : '100px'}>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired isInvalid={!!errors.numeroRue}>
          <FormLabel htmlFor='numeroRue'>Numéro de rue</FormLabel>
          <Input
            id='numeroRue'
            placeholder='Entrez le numéro de rue'
            value={numeroRue}
            onChange={(e) => setNumeroRue(e.target.value)}
          />
          <FormErrorMessage>{errors.numeroRue}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired mt={4} isInvalid={!!errors.rue}>
          <FormLabel htmlFor='rue'>Rue</FormLabel>
          <Input
            id='rue'
            placeholder='Entrez le nom de la rue'
            value={rue}
            onChange={(e) => setRue(e.target.value)}
          />
          <FormErrorMessage>{errors.rue}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired mt={4} isInvalid={!!errors.codePostal}>
          <FormLabel htmlFor='codePostal'>Code postal</FormLabel>
          <Input
            id='codePostal'
            placeholder='Entrez le code postal'
            value={codePostal}
            onChange={(e) => setCodePostal(e.target.value)}
          />
          <FormErrorMessage>{errors.codePostal}</FormErrorMessage>
        </FormControl>
        
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor='vendeur'>Vendeur</FormLabel>
          <Select
            id='vendeur'
            placeholder='Sélectionnez un vendeur'
            value={vendeur}
            isDisabled={true}
          >
            <option value={vendeur}>{vendeur}</option>
          </Select>
        </FormControl>

        <Button mt={4} colorScheme='blue' type='submit' isLoading={isSubmitting}>
          Soumettre l'adresse pour affectation
        </Button>
      </form>

 <VStack mt={8} align="stretch">
      <Text fontSize="xl" fontWeight="bold">10 dernières adresses soumises :</Text>
      {adresses.map((adresse) => (
        <Box key={adresse.id} p={4} borderWidth={1} borderRadius="md">
          <Text>{`${adresse.numeroRue} ${adresse.rue}, ${adresse.codePostal}`}</Text>
          {adresse.traite ? (
            adresse.AD ? (
              <Text color="green.500">AD attribué : {adresse.AD}</Text>
            ) : (
              <Text color="red.500">Aucune adresse trouvée</Text>
            )
          ) : (
            <Text color="orange.500">En attente d'affectation...</Text>
          )}
        </Box>
      ))}
    </VStack>
    </Box>
  );
};

export default AdresseForm;