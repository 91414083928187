import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'lib/firebase'; // Assurez-vous que le chemin d'accès est correct
import { SimpleGrid, Spinner, Center } from '@chakra-ui/react';
import AgencyCard from './agencyCard';

const AgenciesList = () => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAgencies = async () => {
      const querySnapshot = await getDocs(collection(db, 'agencies'));
      const agenciesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgencies(agenciesData);
      setLoading(false);
    };

    fetchAgencies();
  }, []);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <SimpleGrid columns={[1, 2, 3]} spacing="20px" minChildWidth="300px">
      {agencies.map((agency) => (
        <AgencyCard key={agency.id} agency={agency} />
      ))}
    </SimpleGrid>
  );
};

export default AgenciesList;
