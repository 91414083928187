import { db } from "lib/firebase";
import {
    collection,
    deleteDoc,
    getDocs,
    doc,
    addDoc,
    updateDoc
} from "firebase/firestore";

export const addItem = async (name, quantity, image) => {
    // Ajouter le nouveau défi à la collection "challenges"
    await addDoc(collection(db, "items"), {
        name: name,
        quantity: quantity,
        image: image
    });
}

export const modifyItem = async (selectedDoc, image) => {
    // Update the document in Firestore
    const docRef = doc(db, "items", selectedDoc.id);

    console.log(selectedDoc)

    const updatedFields = {
        ...selectedDoc, // inclut toutes les modifications apportées à selectedDoc, y compris teamSalescode
        image: image
    };

    await updateDoc(docRef, updatedFields);
}

export const deleteItem = async (itemId) => {
    try {
        await deleteDoc(doc(db, "items", itemId));

        return true;
    } catch (error) {
        console.error("Error deleting item: ", error);
        return false;
    }
};

export const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, "items"));
    return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
};