import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const { user } = useContext(AuthContext);
  const [vendorNames, setVendorNames] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Pour suivre si les données ont déjà été chargées

  // Charger les noms des vendeurs au montage du composant
  useEffect(() => {
    const fetchVendorNames = async () => {
      try {
        const vendorsResponse = await axios.get("https://app.falconmarketing.fr:3001/api/vendors");
        const vendorsArray = vendorsResponse.data.records || [];
        const vendors = vendorsArray.reduce((acc, current) => {
          acc[current.Id] = current.Name;
          return acc;
        }, {});
        setVendorNames(vendors);
      } catch (error) {
        console.error("Erreur lors du chargement des noms des vendeurs:", error);
      }
    };

    fetchVendorNames();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || isDataLoaded || Object.keys(vendorNames).length === 0) {
        console.log("Les données ont déjà été chargées ou sont en attente des prérequis.");
        return;
      }
  
      console.log("Utilisateur connecté:", user.email);
      console.log("salesCode de l'utilisateur:", user.profileData?.salesCode);
  
      try {
        let allRecords = [];
  
        if (user.profileData?.admin) {
          console.log("Chargement des données pour l'admin...");
          const response = await axios.get(
            `https://app.falconmarketing.fr:3001/uploads/sales_data.json?nocache=${new Date().getTime()}`,
            { headers: { "Cache-Control": "no-cache" } }
          );
          
          if (response.data && Array.isArray(response.data)) {
            allRecords = response.data.map(record => ({
              ...record,
              VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
            }));
            console.log("Données chargées pour l'admin:", allRecords);
          } else {
            throw new Error("Les données reçues ne sont pas dans le format attendu");
          }
        } else if (user.profileData?.manager || user.profileData?.leader) {
          console.log("Chargement des données pour le manager ou le leader...");
          const teamSalescode = user.profileData.teamSalescode || [];
          allRecords = await Promise.all(teamSalescode.map(async (salesCode) => {
            const response = await axios.get(
              `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${salesCode}`
            );
            return response.data.records.map(record => ({
              ...record,
              VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
            }));
          })).then(recordArrays => recordArrays.flat());
          console.log("Données chargées pour le manager/leader:", allRecords);
        } else {
          console.log("Chargement des données pour un utilisateur standard...");
          const response = await axios.get(
            `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${user.profileData.salesCode}`
          );
          allRecords = response.data.records.map(record => ({
            ...record,
            VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
          }));
          console.log("Données chargées pour l'utilisateur:", allRecords);
        }

         
        setData(allRecords);
        setIsDataLoaded(true);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setData([]);
      }
    };
  
    fetchData();
  }, [user, vendorNames, isDataLoaded]);

  

  // Reset isDataLoaded to false if the user changes, allowing for data reload
  useEffect(() => {
    if (user) {
      setIsDataLoaded(false);
    }
  }, [user]);

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};