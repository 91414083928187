import React from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MdOutlineMoney } from "react-icons/md";

const MapComponent = ({ salesData }) => {
  const [selectedSale, setSelectedSale] = React.useState(null);
  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiZG9rZ28iLCJhIjoiY2xzZDA4cHNsMGpiNjJpbWhobm03azNyeCJ9.rfLUyJVQL_JaaHkZV-tmhA"; // Remplacez par votre clé API Mapbox

  return (
    <Map
      initialViewState={{
        latitude: 50.758216, // Utilisez une latitude moyenne ou une latitude spécifique comme point de départ
        longitude: 3.010372, // Utilisez une longitude moyenne ou une longitude spécifique comme point de départ
        zoom: 12,
      }}
      style={{
        width: "100%",
        height: "600px",
        marginTop: "60px",
        borderRadius: "10px",
      }}
      mapStyle="mapbox://styles/mapbox/standard"
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      {salesData.map((sale) => (
        <Marker
          key={sale.result_id}
          latitude={sale.latitude}
          longitude={sale.longitude}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "green",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={() => setSelectedSale(sale)}
          />
        </Marker>
      ))}
  
    </Map>
  );
};

export default MapComponent;
