import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ContextProvider } from 'contexts/AuthContext';
import 'firebase/functions';
import { NotificationProvider } from 'contexts/NotificationContext';
import NotificationActivation from './contexts/NotificationActivation';
import GpsCollector from 'views/admin/default/components/gpscollector';
import { DataProvider } from 'contexts/DataProvider';



if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Service Worker Registered', registration);
    })
    .catch(function(err) {
      console.log('Service Worker registration failed', err);
    });
}

const handleLocation = (location) => {
  console.log('Position GPS actuelle:', location);
};




ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <NotificationProvider>
        <NotificationActivation />
      <ContextProvider>
      <GpsCollector onLocation={handleLocation}/>
        <HashRouter>
        <DataProvider>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Redirect from="/" to="/admin" />
          </Switch>
          </DataProvider>
        </HashRouter>
      </ContextProvider>
      </NotificationProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root') 
);