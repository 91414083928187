import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from 'contexts/NotificationContext';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react';

const NotificationActivation = () => {
    const { requestPermission } = useContext(NotificationContext);
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        // Vérifier si l'API de notifications est prise en charge
        if (typeof Notification === 'undefined' || Notification.permission === 'denied') {
            setShowModal(false);
            return;
        }

        if (Notification.permission !== 'default') {
            setShowModal(false);
        }
    }, []);

    const handleRequestClick = async () => {
        if (typeof Notification !== 'undefined') {
            try {
                await requestPermission();
                setShowModal(false);
            } catch (error) {
                console.error('Erreur lors de la demande de permission:', error);
                setShowModal(false);
            }
        }
    };

    const handleRefuseClick = () => {
        // Gestion du refus ici, si nécessaire
        setShowModal(false);
    };


    return (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Notifications</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <p>Voulez-vous activer les notifications pour rester informé ?</p>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleRequestClick}>
                        Activer les Notifications
                    </Button>
                    <Button colorScheme="red" onClick={handleRefuseClick}>
                        Refuser
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NotificationActivation;