import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getOneChest, removeChest } from "../services/chestsService";
import { nFormatter } from "components/utils/utils";

const ContentModal = ({ isOpen, onClose, chestId, updateDocuments }) => {

  const [chestData, setChestData] = useState(null);
  
  const fetchChest = async () => {
    try {
      const data = await getOneChest(chestId);
      setChestData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChest();
  }, [chestId]);

  const handleDelete = () => {
    removeChest(chestId);
    updateDocuments();
    onClose();
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Liste des items du coffre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {chestData && (
              <>
                {chestData.items.map((item) => (
                  <Flex key={item.id} gap={2} pb={4} alignItems={"center"}>
                    <img src={item.image} width={90} />
                    <Text fontSize={20}><strong>{nFormatter(item.quantity, 2)}</strong> {item.name} | <strong>{item.drop}%</strong></Text>
                  </Flex>
                ))}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {/* Bouton pour annuler l'ajout du défi */}
            <Button colorScheme="red" onClick={() => handleDelete()}>
              Supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContentModal;
