import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Tag,
  TagLabel,
  TagLeftIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Heading,
  Select,
  Stack,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import ReactPaginate from "react-paginate";
import "views/admin/dataTables/components/pagination.css";
import { FaAngleDown } from "react-icons/fa";
import { MdBarChart, MdOutlineRotateLeft, MdDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { Input } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import "views/admin/default/components/i18n.js";
import { useTranslation } from "react-i18next";
import { useData } from "contexts/DataProvider";
import * as XLSX from "xlsx";

const PER_PAGE = 100;

const ManagerTable = () => {
  // Constantes liées à l'état
  // Constantes liées à l'état
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorNames, setVendorNames] = useState({});
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "CreatedDate",
    ascending: false,
  });
  const [collapsedRowId, setCollapsedRowId] = useState(null);
  const [filter, setFilter] = useState({ period: "Tous", status: "Tous" });
  const [searchValue, setSearchValue] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [offerFilter, setOfferFilter] = useState("Tous");

  // Constantes de configuration
  const PER_PAGE = 10;
  const periods = ["Semaine", "Mois", "Année"];
  const statuses = [
    "Tous",
    "EnCours",
    "EnCoursDeRattrapage",
    "Error",
    "Validated",
    "Payed",
    "RaccordOK",
    "RaccordKO",
  ];
  const SFR_OFFERS = [
    "Mobile 200 Go 5G",
    "SFR PREMIUM",
    "Mobile 2h 100 Mo",
    "Mobile 100 Go 5G",
    "Mobile 250 Go 5G",
    "SFR POWER",
    "100Go 5G SIM Seule",
    "Mobile 5 Go 4G+",
    "Mobile 220 Go 5G",
    "SFR",
    "Sfr Power TV box 8",
    "Sfr Power box 8",
    "Mobile 140 Go 5G",
    "Mobile 80 Go 4G+",
    "Mobile 120 Go 5G",
    "Mobile 110 Go 5G",
    "Mobile 160 Go 5G",
    "Sfr box 8",
    "Sfr Power TV",
  ];

  // Fonctions utilitaires
  const formatDate = (date, isPaymentDate = false, displayTime = true) => {
    if (!date) {
      return "";
    }
    const d = new Date(date);
    let month = d.getMonth() + 1;
    let year = d.getFullYear();

    // L'heure et les minutes
    let hours = d.getHours();
    let minutes = d.getMinutes();

    if (isPaymentDate) {
      month += 1;
      if (month > 12) {
        month = 1; // reset to January
        year++; // increment year
      }
    }

    return isNaN(d.getTime())
      ? ""
      : `${d.getDate().toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}` +
          (displayTime
            ? ` ${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}`
            : ""); // ajoute l'heure et les minutes si displayTime est vrai
  };

  const getDiffDays = (date1, date2) => {
    return Math.round(Math.abs((date1 - date2) / oneDay));
  };

  const extractPostalCode = (address) => {
    // Expression régulière pour trouver un code postal (séquence de 5 chiffres)
    const postalCodeRegex = /(\d{5})/;
    const matches = address.match(postalCodeRegex);

    if (matches) {
      const postalCode = matches[0];
      // Retourne uniquement le code postal sans les parenthèses
      return postalCode;
    }

    return ""; // Retourne une chaîne vide si aucun code postal n'est trouvé
  };

  const resetDatePicker = () => {
    setSelectedDate(null); // Assuming setSelectedDate is a state setter for controlling the date picker's value
  };

  // Hooks et contexte
  const { colorMode } = useColorMode();
  const { user } = React.useContext(AuthContext);
  const jsonData = useData();
  const { t } = useTranslation();

  // Effets secondaires
  useEffect(() => {
    // Filtrer d'abord les enregistrements par période et statut
    let filtered = filterRecords(filter.period, filter.status);
    // Ensuite, appliquer la recherche sur les enregistrements filtrés
    filtered = searchRecords(filtered);
    setFilteredRecords(filtered); // Mettre à jour l'état avec les enregistrements filtrés
  }, [
    records,
    filter,
    searchValue,
    selectedMonth,
    selectedDate,
    selectedVendor,
    offerFilter,
  ]);

  useEffect(() => {
    console.log("Données chargées du DataProvider ok:", jsonData);
    if (jsonData) {
      setRecords(jsonData);
      setFilteredRecords(jsonData);
  
      // Extraire les noms de vendeurs uniques
      const uniqueVendorNames = {};
      jsonData.forEach((record) => {
        uniqueVendorNames[record.VendorName] = true;
      });
      setVendorNames(uniqueVendorNames);
    }
  }, [jsonData]);


const handleVendorChange = (e) => {
  setSelectedVendor(e.target.value);
};

  // Gestionnaires d'événements
  const handleOpenModal = (record) => {
    setCurrentRecord(record);
    setIsOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Définir la date sélectionnée
    const filtered = filterRecords(
      filter.period,
      filter.status,
      filter.hasConnectingDate
    );
    setFilteredRecords(filtered);
  };

  const handleFilter = (period, status = "Tous", hasConnectingDate) => {
    const filtered = filterRecords(period, status, hasConnectingDate);
    setFilteredRecords(filtered);
    setFilter({ period, status });
  };

  const handleCollapseToggle = (rowId) => {
    if (collapsedRowId === rowId) {
      setCollapsedRowId(null);
    } else {
      setCollapsedRowId(rowId);
    }
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleDownloadExcel = () => {
    if (filteredRecords) {
      const sortedData = sortRecords(filteredRecords);
      const worksheet = XLSX.utils.json_to_sheet(sortedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");
      XLSX.writeFile(workbook, "sales_data.xlsx");
    }
  };

  const toggleSortDirection = (key) => {
    setSortConfig((prevConfig) => {
      return {
        key: key,
        ascending: prevConfig.key === key ? !prevConfig.ascending : true,
      };
    });
  };

  // Fonctions de filtrage et de tri
  const sortRecords = (records) => {
    return records.sort((a, b) => {
      const dateA = new Date(a[sortConfig.key]);
      const dateB = new Date(b[sortConfig.key]);
      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
        return 0;
      }
      if (isNaN(dateA.getTime())) {
        return sortConfig.ascending ? 1 : -1;
      }
      if (isNaN(dateB.getTime())) {
        return sortConfig.ascending ? -1 : 1;
      }
      return sortConfig.ascending ? dateA - dateB : dateB - dateA;
    });
  };

  const filterRecords = (period, status, hasConnectingDate) => {
    const cutoffDate = new Date("2023-11-01"); // 1er novembre 2023

    // Filtrer d'abord les enregistrements par date
    let filteredByDate = records.filter((record) => {
      const recordDate = new Date(record.CreatedDate);
      return recordDate >= cutoffDate;
    });

    // Appliquer le filtrage par période sur filteredByDate
    let filteredByPeriod = filteredByDate.filter((record) => {
      const recordDate = new Date(record.CreatedDate);
      const diffDays = Math.round(Math.abs((now - recordDate) / oneDay));

      switch (period) {
        case "Semaine":
          return diffDays <= 7;
        case "Mois":
          return diffDays <= 30;
        case "Année":
          return diffDays <= 365;
        default:
          return true;
      }
    });

    // Appliquer le filtrage par statut sur filteredByPeriod
    let filteredByStatus = filteredByPeriod.filter((record) => {
      if (status === "Tous") {
        return true;
      }
      return (
        record.Status__c === status || record.ConnectionStatus__c === status
      );
    });

    // Appliquer le filtrage par offre en fonction du filtre sélectionné (SFR/Bouygues)
    if (offerFilter === "SFR") {
      filteredByStatus = filteredByStatus.filter((record) =>
        SFR_OFFERS.includes(record.OfferName__c)
      );
    } else if (offerFilter === "Bouygues") {
      filteredByStatus = filteredByStatus.filter(
        (record) => !SFR_OFFERS.includes(record.OfferName__c)
      );
    }

    // Appliquer d'autres filtres sur filteredByStatus
    if (hasConnectingDate) {
      filteredByStatus = filteredByStatus.filter((record) => {
        if (
          record.ConnectingDatePlanned__c &&
          record.ConnectingDatePlanned__c.length > 0
        ) {
          const connectingDate = new Date(record.ConnectingDatePlanned__c);
          const diffDaysConnecting = getDiffDays(now, connectingDate);
          return diffDaysConnecting >= -1 && diffDaysConnecting <= 1;
        }
        return false;
      });
    }

    if (selectedMonth) {
      filteredByStatus = filteredByStatus.filter((record) => {
        if (record.DateOfPayment__c) {
          const paymentMonth = new Date(record.DateOfPayment__c).getMonth() + 1;
          return parseInt(selectedMonth) === paymentMonth;
        }
        return false;
      });
    }

    if (selectedDate) {
      const selectedMonth = selectedDate.getMonth();
      const selectedYear = selectedDate.getFullYear();

      filteredByStatus = filteredByStatus.filter((record) => {
        if (record.DateOfPayment__c) {
          const paymentDate = new Date(record.DateOfPayment__c);
          const paymentMonth = paymentDate.getMonth();
          const paymentYear = paymentDate.getFullYear();

          const isSameYear = selectedYear === paymentYear;
          const isPreviousMonth =
            (selectedMonth === 0 && paymentMonth === 11 && !isSameYear) ||
            (selectedMonth === paymentMonth + 1 && isSameYear);

          return isPreviousMonth;
        }
        return false;
      });
    }

     // Appliquer le filtrage par vendeur
  if (selectedVendor) {
    filteredByStatus = filteredByStatus.filter((record) => record.VendorName === selectedVendor);
  }


    return filteredByStatus;
  };

  const searchRecords = (records) => {
    return records.filter((record) => {
      const searchLower = searchValue.toLowerCase();
      return (
        record.TchProspectName__c?.toLowerCase().includes(searchLower) ||
        record.ProspectMobilePhone__c?.toLowerCase().includes(searchLower) ||
        record.OrderNumber__c?.toLowerCase().includes(searchLower) ||
        record.VendorName?.toLowerCase().includes(searchLower)
      );
    });
  };

  // Calculs dérivés
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(filteredRecords.length / PER_PAGE);
  const sortedRecords = sortRecords(filteredRecords).map((record) => ({
    ...record,
    CreatedDate: formatDate(record.CreatedDate, false, true), // afficher l'heure
    // Les autres champs...
    ConnectingDatePlanned__c: formatDate(
      record.ConnectingDatePlanned__c,
      false,
      false
    ), // ne pas afficher l'heure
    DateOfPayment__c: formatDate(record.DateOfPayment__c, true, false), // ne pas afficher l'heure mais ajouter un mois
  }));

  const filteredByOffer = useMemo(() => {
    return filteredRecords.filter((record) => {
      if (offerFilter === "SFR") {
        return SFR_OFFERS.includes(record.OfferName__c);
      } else if (offerFilter === "Bouygues") {
        return !SFR_OFFERS.includes(record.OfferName__c);
      }
      return true; // Retourne tous les enregistrements si aucun filtre spécifique n'est sélectionné
    });
  }, [filteredRecords, offerFilter]);
  const bgColor = useColorModeValue("white", "gray.700");

  const getRowColors = (status) => {
    const lightColors = {
      ToConfirm: "rgba(0, 108, 254, 0.03)",
      Validated: "rgba(3, 255, 0, 0.3)",
      Progress: "rgba(3, 255, 0, 0.1)",
      Error: "rgba(255, 0, 0, 0.3)",
      Payed: "rgba(8, 254, 0, 0.91)",
    };

    const darkColors = {
      ToConfirm: "rgba(0, 108, 254, 0.1)",
      Validated: "rgba(3, 255, 0, 0.4)",
      Progress: "rgba(3, 255, 0, 0.2)",
      Error: "rgba(255, 0, 0, 0.4)",
      Payed: "rgba(8, 254, 0, 0.91)",
    };

    return {
      light: lightColors[status] || "",
      dark: darkColors[status] || "",
    };
  };

  const getStatusColorScheme = (status) => {
    switch (status) {
      case "EnCours":
        return "teal";
      case "EnCoursDeRattrapage":
        return "orange";
      case "Error":
        return "red";
      case "Validated":
        return "green";
      case "Payed":
        return "teal";
      case "RaccordOK":
        return "blue";
      case "RaccordKO":
        return "red";
      case "ToConfirm":
        return "blue";
      case "Progress":
        return "green";
      default:
        return "gray";
    }
  };

  function translateStatus(status) {
    switch (status) {
      case "EnCours":
        return "En cours";
      case "RaccordKO":
        return "Raccordement KO";
      case "RaccordOK":
        return "Raccordé";
      case "Inconnu":
        return "Inconnu";
      case "EnCoursDeRattrapage":
        return "En cours de rattrapage";
      default:
        return "";
    }
  }

  const getRowColor = () => {
    // Retourne une chaîne vide pour ne pas appliquer de couleur de fond basée sur le statut
    // Ou retournez `bgColor` pour utiliser la couleur de fond définie globalement pour le composant
    return bgColor; // Ou "" si vous ne souhaitez pas de couleur de fond du tout
  };

  return (
    <Box
      bg={bgColor}
      borderRadius="5px"
      boxShadow="0 0 5px 1px rgba(0, 0, 0, 0.1)"
      p="10px"
      overflow="auto"
      maxH="auto"
      maxW="100%"
      minH="1000px"
      minW="300px"
    >
      <Flex
        direction={{ base: "column", md: "row" }} // Flex direction adapte pour les différents écrans
        justify="space-between" // Répartit l'espace entre les éléments horizontalement
        alignItems="center" // Centre les éléments verticalement pour un alignement uniforme
        wrap="wrap" // Permet aux éléments de passer à la ligne sur des écrans plus petits
        mb={4}
      >
        {/* Section de recherche */}
        <Box
          flex={{ base: "1 1 100%", md: "1 1 auto" }}
          minWidth={{ base: "100%", md: "300px" }}
          mb={{ base: 4, md: 0 }}
        >
          <Text mb={2}>Recherche:</Text>
          <Input
            placeholder="Tapez pour rechercher..."
            value={searchValue}
            onChange={handleSearchChange}
            bg={colorMode === "dark" ? "gray.800" : "gray.100"}
            color={colorMode === "dark" ? "white" : "black"}
          />
        </Box>

        {/* Section de filtrage par opérateur */}
        <Box
          flex={{ base: "1 1 100%", md: "1 1 auto" }}
          minWidth={{ base: "100%", md: "200px" }}
          mb={{ base: 4, md: 0 }}
        >
          <Text mb={2}>Opérateur:</Text>
          <Select
            onChange={(e) => setOfferFilter(e.target.value)}
            placeholder="Filtrer par fournisseur"
          >
            <option value="Tous">Tous</option>
            <option value="SFR">SFR</option>
            <option value="Bouygues">Bouygues</option>
          </Select>
        </Box>

        {/* Section de filtrage par période */}
        <Box
          flex={{ base: "1 1 100%", md: "1 1 auto" }}
          minWidth={{ base: "100%", md: "200px" }}
          mb={{ base: 4, md: 0 }}
        >
          <Text mb={2}>Période :</Text>
          <Select
            onChange={(e) => handleFilter(e.target.value, filter.status)}
            value={filter.period}
          >
            <option value="Tous">Tous</option>
            {periods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </Select>
        </Box>

        {/* Section de filtrage par statut */}
        <Box
          flex={{ base: "1 1 100%", md: "1 1 auto" }}
          minWidth={{ base: "100%", md: "200px" }}
        >
          <Text mb={2}>Statut :</Text>
          <Select
            onChange={(e) => handleFilter(filter.period, e.target.value)}
            value={filter.status}
          >
            {statuses.map((status) => (
              <option key={status} value={status}>
                {t(status)}
              </option>
            ))}
          </Select>
        </Box>
        <Box flex={{ base: "1 1 100%", md: "1 1 auto" }} minWidth={{ base: "100%", md: "200px" }} mb={{ base: 4, md: 0 }}>
  <Text mb={2}>Vendeur :</Text>
  <Select value={selectedVendor} onChange={handleVendorChange} placeholder="Filtrer par vendeur">
  <option value="">Tous</option>
  {Object.keys(vendorNames).sort().map((vendorName) => (
    <option key={vendorName} value={vendorName}>
      {vendorName}
    </option>
  ))}
</Select>
</Box>
      </Flex>

      {/* Boutons d'action */}
      <Flex justifyContent="space-between" mb={4}>
        <Link to="/admin/statistiques">
          <Button leftIcon={<MdBarChart />} colorScheme="teal" variant="solid">
            Statistiques
          </Button>
        </Link>
        <Button
          onClick={handleDownloadExcel}
          colorScheme="blue" // Utilisez le même schéma de couleurs que les autres boutons pour la cohérence
          variant="solid" // Assurez-vous que le variant est cohérent avec les autres boutons
          leftIcon={<MdDownload />} // Remplacez MdDownload par l'icône de votre choix qui correspond à l'action de facturation/téléchargement
        >
          Télécharger Excel
        </Button>
      </Flex>

      <Box mb={4} spacing={20}>
        <Text fontWeight="bold">Facturation :</Text>
        <Flex direction="row" alignItems="center">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText="Choisir une date"
          />
          <Button onClick={resetDatePicker} variant="outline" marginLeft={2}>
            <MdOutlineRotateLeft />
          </Button>
        </Flex>
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Table
          variant="simple"
          overflow={{ base: "auto", md: "auto" }}
          size="sm"
        >
          <Thead>
            <Tr>
              <Th
                borderColor="gray.200"
                borderWidth="1px"
                onClick={() => toggleSortDirection("CreatedDate")}
                style={{ cursor: "pointer" }}
              >
                Date de la vente
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Vendeur
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Nom
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Numéro de téléphone
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Code Postal
              </Th>{" "}
              {/* Nouvelle colonne pour le code postal */}
              <Th borderColor="gray.200" borderWidth="1px">
                Offre
              </Th>{" "}
              {/* Nouvelle colonne pour l'offre */}
              <Th
                borderColor="gray.200"
                borderWidth="1px"
                onClick={() => toggleSortDirection("ConnectingDatePlanned__c")}
                style={{ cursor: "pointer" }}
              >
                Date de raccordement prévue
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Statut
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Raccordement
              </Th>
              <Th borderColor="gray.200" borderWidth="1px">
                Numéro de Panier
              </Th>{" "}
              {/* Nouvelle colonne pour le numéro de panier */}
            </Tr>
          </Thead>
          <Tbody>
            {sortedRecords
              .slice(offset, offset + PER_PAGE)
              .filter((record) => {
                // Applique le filtre selon l'option sélectionnée
                if (offerFilter === "SFR") {
                  return SFR_OFFERS.includes(record.OfferName__c);
                } else if (offerFilter === "Bouygues") {
                  return !SFR_OFFERS.includes(record.OfferName__c);
                }
                return true; // Retourne tous les enregistrements si "Tous" est sélectionné
              })
              .map((record, index) => (
                <Tr
                  bg={getRowColor(record.Status__c)}
                  onClick={() => handleOpenModal(record)}
                  style={{ cursor: "pointer" }}
                >
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.CreatedDate}
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.VendorName}
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.TchProspectName__c}
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    <a
                      href={`tel:${record.ProspectMobilePhone__c}`}
                      style={{ color: "blue" }}
                    >
                      {record.ProspectMobilePhone__c}
                    </a>
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    {extractPostalCode(record.TchAddress__c)}
                  </Td>{" "}
                  {/* Afficher le code postal */}
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.OfferName__c}
                  </Td>{" "}
                  {/* Afficher l'offre */}
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.ConnectingDatePlanned__c}
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    <Tag
                      size="sm"
                      colorScheme={getStatusColorScheme(record.Status__c)}
                      variant="solid"
                    >
                      <TagLabel>{t(record.Status__c)}</TagLabel>
                    </Tag>
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.ConnectionStatus__c ? (
                      <Tag
                        size="sm"
                        colorScheme={getStatusColorScheme(
                          record.ConnectionStatus__c
                        )}
                        variant="solid"
                      >
                        <TagLabel>
                          {translateStatus(record.ConnectionStatus__c)}
                        </TagLabel>
                      </Tag>
                    ) : null}
                  </Td>
                  <Td borderColor="gray.200" borderWidth="1px">
                    {record.BasketNumber__c}
                  </Td>{" "}
                  {/* Afficher le numéro de panier */}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {/* Vue mobile et tablette (cartes) */}
      <Box display={{ base: "block", md: "none" }}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {sortedRecords
            .slice(offset, offset + PER_PAGE)
            .filter((record) => {
              // Applique le filtre selon l'option sélectionnée
              if (offerFilter === "SFR") {
                return SFR_OFFERS.includes(record.OfferName__c);
              } else if (offerFilter === "Bouygues") {
                return !SFR_OFFERS.includes(record.OfferName__c);
              }
              return true; // Retourne tous les enregistrements si "Tous" est sélectionné
            })
            .map((record, index) => (
              <GridItem
                key={index}
                bg={getRowColor(record.Status__c)}
                p={6}
                rounded="md"
                boxShadow="md"
                onClick={() => handleOpenModal(record)}
                cursor="pointer"
              >
                <VStack align="start" spacing={2}>
                  <Text fontWeight="bold">{record.TchProspectName__c}</Text>
                  <Text>
                    <strong>Numéro :</strong>{" "}
                    <a
                      href={`tel:${record.ProspectMobilePhone__c}`}
                      style={{ color: "blue" }}
                    >
                      {record.ProspectMobilePhone__c}
                    </a>
                  </Text>
                  <Text>
                    <strong>Offre :</strong> {record.OfferName__c}
                  </Text>
                  <Text>
                    <strong>Date de raccordement</strong> :{" "}
                    {record.ConnectingDatePlanned__c}
                  </Text>
                  <Tag
                    size="sm"
                    colorScheme={getStatusColorScheme(record.Status__c)}
                    variant="solid"
                  >
                    <TagLabel>{t(record.Status__c)}</TagLabel>
                  </Tag>
                </VStack>
              </GridItem>
            ))}
        </Grid>
      </Box>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Détails de la vente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentRecord && (
              <VStack align="start" mt={2} mb={2}>
                <Text>
                  <strong>Client :</strong> {currentRecord.TchProspectName__c}
                </Text>
                <Text>
                  <strong>Numéro :</strong>{" "}
                  <a
                    href={`tel:${currentRecord.ProspectMobilePhone__c}`}
                    style={{ color: "blue" }}
                  >
                    {currentRecord.ProspectMobilePhone__c}
                  </a>
                </Text>
                <Text>
                  <strong>Adresse :</strong> {currentRecord.TchAddress__c}
                </Text>
                <Text>
                  <strong>Statut du raccordement :</strong>{" "}
                  {currentRecord.ConnectionStatus__c}
                </Text>
                <Text>
                  <strong>Offre :</strong> {currentRecord.OfferName__c}
                </Text>
                <Text>
                  <strong>Famille de l'offre :</strong>{" "}
                  {currentRecord.FamilyOffer__c}
                </Text>
                <Text>
                  <strong>Date de signature :</strong>{" "}
                  {formatDate(currentRecord.SignatureDate__c)}
                </Text>
                <Text>
                  <strong>Date de validation :</strong>{" "}
                  {formatDate(currentRecord.ValidationDate__c)}
                </Text>
                <Text>
                  <strong>Type de vente :</strong>{" "}
                  {currentRecord.CustomerType__c}
                </Text>
                <Text>
                  <strong>Numéro de commande :</strong>{" "}
                  {currentRecord.OrderNumber__c}
                </Text>
                <Text>
                  <strong>Numéro de panier :</strong>{" "}
                  {currentRecord.BasketNumber__c}
                </Text>
                <Text>
                  <strong>Commentaire du technicien :</strong>{" "}
                  {currentRecord.Comment__c}
                </Text>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsOpen(false)}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box mt={6}>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </Box>
    </Box>
  );
};
export default ManagerTable;
