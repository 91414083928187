import { db } from "lib/firebase";
import {
    collection,
    getDocs,
    doc,
    updateDoc
} from "firebase/firestore";


export const fetchWaitingReward = async () => {
    const querySnapshot = await getDocs(collection(db, "waitingReward"));
    const usersSnapshot = await getDocs(collection(db, "users"));
    const itemsSnapshot = await getDocs(collection(db, "items"));

    const users = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const items = itemsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return querySnapshot.docs.map((doc) => {
        const itemId = doc.data().itemId;
        const item = items.find((item) => item.id === itemId);
        const user = users.find((user) => user?.waitingRewardIds?.includes(doc.id));
        const date = new Date(doc.data().date);
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        return {
            id: doc.id,
            ...doc.data(),
            item,
            user: user ? { name: user.name,  } : null,
            date: formattedDate,
        };
    });
};

export const changeWaitingRewardState = async (waitingRewardId, isCompleted) => {
    const waitingRewardRef = doc(db, "waitingReward", waitingRewardId);
    await updateDoc(waitingRewardRef, { completed: isCompleted });
}