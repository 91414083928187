/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, { useEffect, useContext } from "react";
  import { AuthContext } from "contexts/AuthContext";
  import AgenciesList from "./components/agenciesList";
  import ManageAgencies from "./components/manageAgencies";
  
  export default function UserReports() {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
      // Utilisez useContext pour accéder aux données de l'utilisateur
      const { user } = useContext(AuthContext);
  
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px"></SimpleGrid>
            <AgenciesList />
            <ManageAgencies />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 1, xl: 1 }}
          gap="20px"
          mb="20px"
        ></SimpleGrid>
      </Box>
    );
  }
  