import { Box, Button, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchChests } from "../services/chestsService";
import AddModal from "./addModal";
import ChestCard from "./chestCard";
import ContentModal from "./contentModal";

const ChestList = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isContentOpen,
    onOpen: onContentOpen,
    onClose: onContentClose,
  } = useDisclosure();

  const handleContentOpen = (id) => {
    setSelectedDocumentId(id);
    onContentOpen();
  };

  const fetchDocuments = async () => {
    try {
      const chests = await fetchChests();
      setDocuments(chests);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      <Box my={2} mt={6}>
        <Button colorScheme="green" onClick={onAddOpen}>
          Ajouter un coffre
        </Button>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap="20px" mb="20px">
        {documents.map((document) => (
          <>
            <ChestCard chestName={document.name} onContentOpen={() => handleContentOpen(document.id)} />
          </>
        ))}

        <AddModal
          isOpen={isAddOpen}
          onClose={onAddClose}
          updateDocuments={fetchDocuments}
        />

        <ContentModal isOpen={isContentOpen} onClose={onContentClose} chestId={selectedDocumentId} updateDocuments={fetchDocuments}/>
      </SimpleGrid>
    </>
  );
};

export default ChestList;
