import React from "react";
import CreateAgencyComponent from "views/admin/default/components/createAgency";

const ManageAgencies = () => {
    return (
        <div>
        <CreateAgencyComponent />
        </div>
    );
    }

export default ManageAgencies;