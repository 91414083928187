import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { doc, updateDoc, GeoPoint, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from 'lib/firebase';

const GpsCollector = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Assurez-vous que vous avez accès à l'email de l'utilisateur ici
          if (user.email) {
            try {
              // Utilisez l'email pour retrouver le document Firestore de l'utilisateur
              const q = query(collection(db, "users"), where("email", "==", user.email));
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach(async (docSnapshot) => {
                const userDocRef = doc(db, 'users', docSnapshot.id);
                const geopoint = new GeoPoint(latitude, longitude);
                // Créez un objet Date JavaScript pour la date et l'heure actuelles
                const lastLogin = new Date();
                await updateDoc(userDocRef, { geopoint, lastLogin: lastLogin });
                console.log('GeoPoint and last login date updated successfully');
              });
            } catch (error) {
              console.error('Error updating document:', error);
            }
          }
        },
        (error) => {
          console.error('Geolocation error:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, [user]);

  return null; // Ce composant n'affiche rien
};

export default GpsCollector;
