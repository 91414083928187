import React, { useEffect, useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Select,
  Heading,
  useToast,
} from '@chakra-ui/react';

const CreateAgencyComponent = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [agencyName, setAgencyName] = useState('');
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedTeamLeaders, setSelectedTeamLeaders] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchVendors = async () => {
      const response = await fetch('https://app.falconmarketing.fr:3001/api/vendors');
      const data = await response.json();
      setVendors(data.records);
      setLoading(false);
    };

    fetchVendors();
  }, []);

  const handleCreateAgency = async () => {
    try {
      await addDoc(collection(db, 'agencies'), {
        name: agencyName,
        manager: selectedManager,
        teamLeaders: selectedTeamLeaders,
        vendors: selectedVendors,
      });
      toast({
        title: 'Agence créée avec succès',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur lors de la création de l\'agence',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSelectionChange = (setter) => (e) => {
    const value = Array.from(e.target.selectedOptions, (option) => option.value);
    setter(value);
  };

  {vendors
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .map((vendor) => (
      <option key={vendor.Id} value={vendor.Id}>{vendor.Name}</option>
  ))}

  if (loading) {
    return <Box padding="4" boxShadow="lg" bg="white">Chargement...</Box>;
  }

  return (
    <Box padding="4" boxShadow="lg" bg="white" borderRadius="6px">
      <Heading mb="6">Créer une agence</Heading>
      <FormControl id="agency-name" isRequired mb="4">
        <FormLabel>Nom de l'agence</FormLabel>
        <Input
          placeholder="Nom de l'agence"
          value={agencyName}
          onChange={(e) => setAgencyName(e.target.value)}
        />
      </FormControl>
      <FormControl id="manager-select" isRequired mb="4">
        <FormLabel>Manager</FormLabel>
        <Select  placeholder="Sélectionnez un manager" onChange={(e) => setSelectedManager(e.target.value)}>
          {vendors.map((vendor) => (
            <option key={vendor.Id} value={vendor.Id}>{vendor.Name}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="team-leaders-select" mb="4">
        <FormLabel>Chefs d'équipe</FormLabel>
        <Select
  multiple
  onChange={handleSelectionChange(setSelectedTeamLeaders)}
  height="300px" // Augmentez la hauteur ici
  fontSize="1rem"
  sx={{
    '& > option': {
    },
  }}
>
  {vendors.map((vendor) => (
    <option key={vendor.Id} value={vendor.Id}>{vendor.Name}</option>
  ))}
</Select>
      </FormControl>
      <FormControl id="vendors-select" mb="4">
        <FormLabel>Vendeurs</FormLabel>
        <Select
  size="lg"
  height="300px" // Augmentez la hauteur ici
  multiple
  onChange={handleSelectionChange(setSelectedVendors)}
>
  {vendors.map((vendor) => (
    <option key={vendor.Id} value={vendor.Id}>{vendor.Name}</option>
  ))}
</Select>
      </FormControl>
      <Button colorScheme="blue" mt="4" onClick={handleCreateAgency}>Créer l'agence</Button>
    </Box>
  );
};

export default CreateAgencyComponent;
