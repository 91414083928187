import React, { useState } from 'react';
import axios from 'axios'; // Assurez-vous d'installer axios pour la requête HTTP

const EditPDFComponent = () => {
  const [file, setFile] = useState(null);
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [text, setText] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleXChange = (e) => {
    setX(e.target.value);
  };

  const handleYChange = (e) => {
    setY(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('x', x);
    formData.append('y', y);
    formData.append('text', text);

    try {
      const response = await axios.post('https://app.falconmarketing.fr:3001/api/edit-pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', // Important pour le téléchargement de fichiers
      });

      // Crée un URL pour le fichier téléchargé et le force à télécharger
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'edited-pdf.pdf'); // Nomme le fichier téléchargé
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div style={{ marginTop: '100px' }}>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} required />
        <input type="text" value={x} onChange={handleXChange} placeholder="X Coordinate" required />
        <input type="text" value={y} onChange={handleYChange} placeholder="Y Coordinate" required />
        <input type="text" value={text} onChange={handleTextChange} placeholder="Text to add" required />
        <button type="submit">Edit PDF</button>
      </form>
    </div>
  );
};

export default EditPDFComponent;
