import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react"
import { AuthContext } from "contexts/AuthContext"; // à ajuster en fonction du chemin de votre fichier de contexte

function SalesRanking() {
    const [salesData, setSalesData] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        // Ne faire la requête que si l'utilisateur est un administrateur
        if (user && user.profileData && user.profileData.admin) {
            fetchData();
        }
    }, [user]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://app.falconmarketing.fr:3001/api/sales?nocache=${new Date().getTime()}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                },
            });
            const salesData = response.data;
            
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();
            
            // Filtrer les données pour inclure uniquement les ventes du mois en cours
            const filteredSalesData = salesData.filter(sale => {
                const saleDate = new Date(sale.CreationDate__c);
                return saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear && sale.ConnectionStatus__c === "RaccordOK";
            });

            // Calculer le nombre de contrats pour chaque vendeur
            const vendorSalesCount = {};
            filteredSalesData.forEach(sale => {
                if(!vendorSalesCount[sale.VendorName__c]) {
                    vendorSalesCount[sale.VendorName__c] = 1;
                } else {
                    vendorSalesCount[sale.VendorName__c]++;
                }
            });

            // Créer une liste de vendeurs avec leur nombre de contrats
            let salesRanking = Object.keys(vendorSalesCount).map(vendorName => {
                return { name: vendorName, sales: vendorSalesCount[vendorName] }
            });

            // Trier la liste par nombre de contrats en ordre décroissant
            salesRanking.sort((a, b) => b.sales - a.sales);

            setSalesData(salesRanking);
        } catch (error) {
            console.error("Erreur lors de la récupération des données de Salesforce :", error);
        }
    };

    // S'assurer que l'utilisateur est connecté et est un administrateur
    if (!user || !user.profileData || !user.profileData.admin) {
        return null;
    }

    return (
        <Box borderRadius="lg" boxShadow="lg" p="6" bg="white">
            <Text mb="4" fontSize="xl" fontWeight="bold">Classement des vendeurs</Text>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Rang</Th>
                        <Th>Vendeur</Th>
                        <Th>Ventes Raccordées</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {salesData.map((sale, index) => (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{sale.name}</Td>
                            <Td>{sale.sales}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default SalesRanking;
