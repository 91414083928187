import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
    fetchChallenges
} from "../services/challengesService"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    useDisclosure,
    Image,
    Flex,
} from "@chakra-ui/react";
import { nFormatter } from "components/utils/utils";


import ModifyModal from "./modifyModal";
import AddModal from "./addModal";
import DeleteModal from "./deleteModal";

// Fonction pour afficher la liste des challenges
export default function ChallengeList() {
    // État local pour stocker la liste des challenges
    const [documents, setDocuments] = useState([]);
    // État local pour gérer la pagination
    const [currentPage, setCurrentPage] = useState(1);
    // État local pour stocker l'ID du challenge à supprimer
    const [deletingChallengeId, setDeletingChallengeId] = useState(null);
    // State pour le gerer la selection d'un challenge
    const [selectedDoc, setSelectedDoc] = useState(null);


    // Hooks de gestion de l'ouverture et de la fermeture des modales
    const {
        isOpen: isModifyOpen,
        onOpen: onModifyOpen,
        onClose: onModifyClose
    } = useDisclosure();
    const {
        isOpen: isAddOpen,
        onOpen: onAddOpen,
        onClose: onAddClose
    } = useDisclosure();
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();

    // Constantes pour la pagination
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageCount = Math.ceil(documents.length / itemsPerPage);

    // Fonction pour gérer le changement de page
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    // Fonction pour récupérer la liste des challenges depuis Firestore
    const fetchDocuments = async () => {
        try {
            const challenges = await fetchChallenges();
            setDocuments(challenges);
        } catch (error) {
            console.error("Error fetching challenges: ", error);
        }
    };


    // Fonction pour ouvrir la modal de confirmation de suppression d'un challenge
    const openDeleteConfirmation = (challengeId) => {
        setDeletingChallengeId(challengeId);
        onDeleteOpen();
    };

    // Fonction pour ouvrir la modal de modification d'un challenge
    const editDocument = (doc) => {
        setSelectedDoc(doc);
        onModifyOpen();
    };

    // Effet pour récupérer la liste des challenges lors du premier rendu
    useEffect(() => {
        fetchDocuments()
    }, []);

    documents.map((doc) => {
        console.log(doc);
    })

    // Rendu du composant
    return (
        <>
            {/* Bouton pour ajouter un nouveau challenge */}
            <Box my={2} mt={6}>
                <Button
                    colorScheme="green"
                    onClick={onAddOpen}
                >
                    Ajouter un challenge
                </Button>
            </Box>
            {/* Tableau pour afficher la liste des challenges */}
            <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
                <Table variant="striped" colorScheme="blue">
                    <Thead>
                        <Tr>
                            <Th>Description</Th>
                            <Th>Récompense</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {documents
                            .slice(indexOfFirstItem, indexOfLastItem)
                            .map((doc) => (
                                <Tr key={doc.id}>
                                    <Td>{doc.description}</Td>
                                    {doc.rewardType === "item" && (
                                        <Td>
                                            <Flex align="center" gap={1}>
                                                {nFormatter(doc.item.quantity, 2)}
                                                <Image src={doc.item.image} width={6} height={6} />
                                            </Flex>
                                        </Td>
                                    )}
                                    {doc.rewardType === "chest" && (
                                        <Td>
                                            <Flex align="center" gap={1}>
                                                1
                                                <Image src='https://png.pngtree.com/png-clipart/20221211/ourmid/pngtree-old-rusty-closed-treasure-chest-side-view-transparent-png-image_6520038.png' width={6} height={6} />
                                            </Flex>
                                        </Td>
                                    )}
                                    <Td>
                                        <Button
                                            size="xs"
                                            colorScheme="red"
                                            onClick={() => openDeleteConfirmation(doc.id)}
                                        >
                                            Supprimer
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </Box>

            {/* Pagination */}
            <Box mt={6}>
                <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </Box>

            {/* Modals pour modifier, ajouter et supprimer un challenge */}
            {selectedDoc && (
                <ModifyModal
                    isOpen={isModifyOpen}
                    onClose={onModifyClose}
                    selectedDoc={selectedDoc}
                    setSelectedDoc={setSelectedDoc}
                    documents={documents}
                    setDocuments={setDocuments}
                />
            )}

            <AddModal
                isOpen={isAddOpen}
                onClose={onAddClose}
                updateDocuments={fetchDocuments}
            />

            <DeleteModal
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                challengeId={deletingChallengeId}
                updateDocuments={fetchDocuments}
            />
        </>
    )
}
