import { db } from "lib/firebase";
import {
    collection,
    getDocs,
    updateDoc
} from "firebase/firestore";

export const getUsers = async () => {
    try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = [];

        await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
            const userData = userDoc.data();
            const userProgression = await getUserProgression(userDoc.id);
            const user = {
                id: userDoc.id,
                ...userData,
                progression: userProgression,
            };
            usersData.push(user);
        }));

        return usersData;
    } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs :", error);
        throw error;
    }
};

const getUserProgression = async (userId) => {
    try {
        const progressionSnapshot = await getDocs(collection(db, `users/${userId}/progression`));
        const progressionData = [];
        progressionSnapshot.forEach((progressionDoc) => {
            progressionData.push({
                id: progressionDoc.id,
                ...progressionDoc.data(),
            });
        });
        return progressionData;
    } catch (error) {
        console.error("Erreur lors de la récupération de la progression de l'utilisateur :", error);
        throw error;
    }
};

// Définissez la fonction updateProgression dans le service challengeProgressionService
export const updateProgression = async (userId, progressionValues) => {
    try {
        // Créez une référence au document de progression de l'utilisateur dans la collection "users"
        const progressionSnapshot = await getDocs(collection(db, `users/${userId}/progression`));
        console.log(progressionSnapshot.size + " Lectures")
        // Parcourir chaque document dans l'instantané
        progressionSnapshot.forEach(async (doc) => {
            let data = doc.data();

            const updatedProgression = progressionValues.find(item => item.challengeId === data.challengeId);
            
            if (updatedProgression.progression !== data.progression) {
                console.log("1 Ecriture")
                await updateDoc(
                    doc.ref,
                    { progression: updatedProgression.progression }
                );
            }
        });

    } catch (error) {
        throw new Error(error);
    }
};