// Importez le hook useState et la fonction useEffect depuis React
import { useState, useEffect } from "react";
import Coin from "../../../assets/img/dashboards/coin.png"
import Chest from "../../../assets/img/dashboards/chest.png"
import { updateProgression } from "./services/challengeProgressionService";
// Importez les composants nécessaires depuis Chakra UI
import {
    Box,
    SimpleGrid,
    Select,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Image,
    Flex,
    Button,
    useToast,
} from "@chakra-ui/react";
// Importez la fonction getUsers depuis le service challengeProgressionService
import { getUsers } from "./services/challengeProgressionService";
// Importez la fonction fetchChallenges depuis le service challengesService
import { fetchChallenges } from "../challenge/services/challengesService";

// Définissez le composant ChallengeProgression
export default function ChallengeProgression() {
    // Déclarez les états locaux nécessaires
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [challengesWithProgress, setChallengesWithProgress] = useState([]);
    const [progressions, setProgressions] = useState({});
    const toast = useToast();


    // Utilisez useEffect pour récupérer la liste des utilisateurs lors du premier rendu
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getUsers();
                console.log(usersData.length + " lectures")
                setUsers(usersData);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs :", error);
            }
        };

        fetchUsers();
    }, []);

    // Définissez la fonction pour gérer le changement de sélection d'utilisateur
    const handleUserSelectChange = (event) => {
        const userId = event.target.value;
        const user = users.find(o => o.id === userId);
        setSelectedUser(user);
    };

    // Utilisez useEffect pour récupérer les défis avec la progression de l'utilisateur sélectionné
    useEffect(() => {
        const fetchChallengesWithProgress = async () => {
            if (selectedUser) {
                try {
                    const challenges = await fetchChallenges();
                    console.log(challenges.length + " lectures")
                    const challengesWithProgress = challenges.map(challenge => {
                        const userProgression = selectedUser.progression.find(o => o.challengeId === challenge.id);
                        return {
                            ...challenge,
                            progression: userProgression ? userProgression.progression : 0
                        };
                    });
                    setProgressions(selectedUser.progression)
                    setChallengesWithProgress(challengesWithProgress);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchChallengesWithProgress();
    }, [selectedUser]);

    const getIcon = (rewardType) => {
        if (rewardType === "falcoins") return <Image src={Coin} width={6} height={6} />
        if (rewardType === "coffre") return <Image src={Chest} width={6} height={6} />
        else return (" " + rewardType)
    }

    const handleProgressionChange = (value, challengeId) => {
        // Récupérer la nouvelle valeur de progression
        const newProgression = parseInt(value)
        
        // Mettre à jour le tableau de progression en fonction du défi modifié
        const updatedProgression = progressions.map(item => {
            // Si c'est le défi concerné, mettre à jour sa progression
            if (item.challengeId === challengeId) {
                return {
                    ...item,
                    progression: newProgression
                };
            } else {
                return item; // Sinon, retourner l'élément inchangé
            }
        });
        
        console.log(updatedProgression)
        // Mettre à jour l'état local avec le nouveau tableau de progression
        setProgressions(updatedProgression);
    };


    // Fonction pour mettre à jour la progression lorsque le bouton "Enregistrer" est cliqué
    const handleSaveProgression = async () => {
        try {
            // Vérifiez si un utilisateur est sélectionné
            if (selectedUser) {
                // Appelez la fonction updateProgression avec l'ID de l'utilisateur et les valeurs de progression
                await updateProgression(selectedUser.id, progressions);
                // Affichez une notification ou effectuez d'autres actions après la mise à jour réussie
                toast({
                    title: 'Succès',
                    description: 'Challenge mis à jour',
                    status: 'success',
                    isClosable: true,
                    duration: 5000
                });
                // Mettre à jour les défis avec la progression de l'utilisateur sélectionné
                const updatedChallengesWithProgress = challengesWithProgress.map(challenge => {
                    const userProgression = progressions.find(o => o.challengeId === challenge.id);
                    return {
                        ...challenge,
                        progression: userProgression ? userProgression.progression : 0
                    };
                });
                setChallengesWithProgress(updatedChallengesWithProgress);
            }
        } catch (error) {
            console.error(error);
            // Affichez une notification ou effectuez d'autres actions en cas d'erreur
        }
    };

    // Rendu du composant
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
                <Box mt={6}>
                    <Flex align="center" gap={5}>
                        {/* Utilisez Select pour sélectionner un utilisateur */}
                        <Select variant="" placeholder='Selectionner un commercial' onChange={handleUserSelectChange}>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </Select>
                        <Button paddingX={16} paddingY={7} colorScheme="green" onClick={handleSaveProgression}>Enregister</Button>
                    </Flex>
                </Box>
                
                <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
                    {/* Affichez les défis avec la progression */}
                    <Table variant="striped" colorScheme="blue">
                        <Thead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Récompense</Th>
                                <Th>Progression</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {challengesWithProgress.map(challenge => (
                                <Tr key={challenge.id}>
                                    <Td>{challenge.description}</Td>
                                    <Td>
                                        <Flex align="center" gap={1}>
                                            {challenge.rewardNumber} {getIcon(challenge.rewardType)}
                                        </Flex>
                                    </Td>
                                    <Td>
                                        {/* Utilisez NumberInput pour afficher et modifier la progression */}
                                        <NumberInput
                                            color="black"
                                            rounded={10}
                                            maxW={100}
                                            backgroundColor="white"
                                            key={challenge.progression}
                                            defaultValue={challenge.progression}
                                            max={challenge.stepNumber}
                                            min={0}
                                            onChange={(value) => handleProgressionChange(value, challenge.id)}
                                        >
                                            <NumberInputField
                                                name={challenge.id}
                                            />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </SimpleGrid>
        </Box>
    )
}
