import React from "react";
import { deleteChallenge } from "../services/challengesService";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useToast,
} from "@chakra-ui/react";

export default function DeleteModal({ isOpen, onClose, challengeId, updateDocuments }) {
    const toast = useToast();
    // Fonction pour confirmer la suppression d'un challenge
    const confirmDeleteChallenge = async (challengeId) => {
        try {
            // Supprime le challenge et la progression pour celui-ci pour tous les users
            await deleteChallenge(challengeId);

            // Notification de succès
            toast({
                title: "Succès",
                description: "Le challenge a été supprimé avec succès !",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            // Mise à jour de la liste des documents après la suppression
            updateDocuments();
            // Fermeture de la modal de confirmation
            onClose();
        } catch (error) {
            // Notification d'erreur en cas de problème
            toast({
                title: "Erreur",
                description: 'Une erreur est survenue lors de la suppression du challenge. Veuillez réessayer.',
                status: 'error',
                isClosable: true,
                duration: 5000
            });
            console.error("Error deleting challenge: ", error);
        }
    };
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmation de suppression</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer ce challenge ?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" mr={3} onClick={() => confirmDeleteChallenge(challengeId)}>
                        Confirmer
                    </Button>
                    <Button colorScheme="red" onClick={onClose}>
                        Annuler
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}