import { addDoc, collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "lib/firebase";

export const fetchChests = async () => {
    const querySnapshot = await getDocs(collection(db, "chests"));
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export const addChest = async (chest) => {
    const docRef = await addDoc(collection(db, "chests"), chest);
    return docRef.id;
}

export const removeChest = async (chestId) => {
    await deleteDoc(doc(db, "chests", chestId));
}

// Fonction pour récupérer les données d'un item par ID
const getItemById = async (itemId) => {
    const itemRef = doc(db, "items", itemId); // Remplacez "items" par le nom de votre collection d'items
    const itemSnap = await getDoc(itemRef);
  
    if (itemSnap.exists()) {
      return { id: itemSnap.id, ...itemSnap.data() };
    } else {
      throw new Error(`No such item with ID: ${itemId}`);
    }
  };
  
  export const getOneChest = async (id) => {
    try {
      const chestRef = doc(db, "chests", id); // Remplacez "chests" par le nom de votre collection
      const chestSnap = await getDoc(chestRef);
  
      if (!chestSnap.exists()) {
        throw new Error("No such document !");
      }
  
      const chestData = { id: chestSnap.id, ...chestSnap.data() };
  
      // Récupérer les items associés au coffre
      if (chestData.items && Array.isArray(chestData.items)) {
        const itemsPromises = chestData.items.map(async (item) => {
          const itemData = await getItemById(item.item);
          return { ...item, ...itemData }; // Combiner les données de l'item avec celles de l'entrée dans le coffre
        });
        chestData.items = await Promise.all(itemsPromises);
      }
      console.log(chestData);
      return chestData;
    } catch (error) {
      console.error("Error getting document:", error);
      throw error;
    }
  };