import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdDiversity3,
  MdMap,
  MdOutlineStar,
  MdOutlineStarHalf,
  MdAssignmentReturned
} from 'react-icons/md';
import {
  TbPackageExport
} from "react-icons/tb"
import { GiLockedChest } from "react-icons/gi";



// Admin Imports
import MainDashboard from 'views/admin/default';
import Statistiques from 'views/admin/statistiques';
import Agencies from 'views/admin/agencies';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import SignUpCentered from 'views/auth/signUp';
import ListDocuments from 'views/admin/default/components/listdocuments';
import SalesMap from 'views/admin/default/components/salesmap';
import CommercialMap from 'views/admin/default/components/map';
import EditPDFComponent from 'views/admin/paperwork/components/formPDF';
import AdresseForm from 'views/admin/default/components/AdresseForm';
import Challenge from 'views/admin/challenge';
import ChallengeProgression from 'views/admin/challengeProgression';
import Item from 'views/admin/items';
import Chest from 'views/admin/chest';
import WaitingReward from 'views/admin/waitingReward';


const routes = [
  {
    name: 'Accueil',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Statistiques',
    layout: '/admin',
    path: '/statistiques',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: Statistiques,
  },
  {
    name: "Gestion des équipes",
    layout: "/admin",
    path: "/gestion-agence",
    icon: <Icon as={MdDiversity3} width="20px" height="20px" color="inherit" />,
    component: ListDocuments,
    requiresAdmin: true,
  },
  {
    name: "Gestion des agences",
    layout: "/admin",
    path: "/agencies",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Agencies,
    requiresAdmin: true,
  },
  {
    name: "Carte",
    layout: "/admin",
    path: "/map",
    icon: <Icon as={MdMap} width="20px" height="20px" color="inherit" />,
    component: CommercialMap,
    requiresAdmin: true,
  },
  {
    name: "Carte des ventes",
    layout: "/admin",
    path: "/carte-ventes",
    icon: <Icon as={MdMap} width="20px" height="20px" color="inherit" />,
    component: SalesMap,
    requiresAdmin: true, 
  },
  {
    name: "Challenges",
    layout: "/admin",
    path: "/challenge",
    icon: <Icon as={MdOutlineStar} width="20px" height="20px" color="inherit" />,
    component: Challenge,
    requiresAdmin: true
  },
  {
    name: "Progression Challenges",
    layout: "/admin",
    path: "/progression-challenge",
    icon: <Icon as={MdOutlineStarHalf} width="20px" height="20px" color="inherit" />,
    component: ChallengeProgression,
    requiresAdmin: true
  },
  {
    name: "Items",
    layout: "/admin",
    path: "/items",
    icon: <Icon as={MdOutlineStarHalf} width="20px" height="20px" color="inherit" />,
    component: Item,
    requiresAdmin: true
  },
  {
    name: "Récompenses en attentes",
    layout: "/admin",
    path: "/recompenses",
    icon: <Icon as={TbPackageExport} width="20px" height="20px" color="inherit" />,
    component: WaitingReward,
    requiresAdmin: true
  },
  {
    name: 'Coffres',
    layout: '/admin',
    path: '/coffres',
    icon: <Icon as={GiLockedChest} width="20px" height="20px" color="inherit" />,
    component: Chest,
    requiresAdmin: true,
  },
  {
    name: "Documents",
    layout: "/admin",
    path: "/paperwork",
    icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
    component: EditPDFComponent,
    requiresAdmin: true,
  },
  {
    name: "Affectation des adresses",
    layout: "/admin",
    path: "/adresse-form",
    icon: <Icon as={MdAssignmentReturned} width="20px" height="20px" color="inherit" />,
    component: AdresseForm,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hideLink: true,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
    hideLink: true,
  },



];

export default routes;
