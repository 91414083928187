import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Tag,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';

const LeaderTable = ({ isOpen, onClose, salesCodes }) => {
  const [leaderData, setLeaderData] = useState([]);
  const bgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchLeaderData = async () => {
      try {
        const allRecords = await Promise.all(
          salesCodes.map(async (salesCode) => {
            const response = await axios.get(
              `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${salesCode}`
            );
            return response.data.records;
          })
        ).then((recordArrays) => recordArrays.flat());

        setLeaderData(allRecords);
      } catch (error) {
        console.error('Erreur lors du chargement des données des leaders :', error);
      }
    };

    fetchLeaderData();
  }, [salesCodes]);

  const getStatusColorScheme = (status) => {
    switch (status) {
      case "EnCours":
        return "teal";
      case "EnCoursDeRattrapage":
        return "orange";
      case "Error":
        return "red";
      case "Validated":
        return "green";
      case "Payed":
        return "teal";
      case "RaccordOK":
        return "blue";
      case "RaccordKO":
        return "red";
      case "ToConfirm":
        return "blue";
      case "Progress":
        return "green";
      default:
        return "gray";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Données des leaders/managers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="simple" overflow={{ base: "auto", md: "auto" }} size="sm">
            <Thead>
              <Tr>
                <Th borderColor='gray.200' borderWidth='1px'>Nom</Th>
                <Th borderColor='gray.200' borderWidth='1px'>Numéro de téléphone</Th>
                <Th borderColor='gray.200' borderWidth='1px'>Adresse</Th>
                <Th borderColor='gray.200' borderWidth='1px'>Offre</Th>
                <Th borderColor='gray.200' borderWidth='1px'>Statut</Th>
                <Th borderColor='gray.200' borderWidth='1px'>Statut du raccordement</Th>
              </Tr>
            </Thead>
            <Tbody>
              {leaderData.map((record, index) => (
                <Tr key={index} bg={bgColor}>
                  <Td borderColor='gray.200' borderWidth='1px'>{record.TchProspectName__c}</Td>
                  <Td borderColor='gray.200' borderWidth='1px'>
                    <a href={`tel:${record.ProspectMobilePhone__c}`} style={{ color: "blue" }}>
                      {record.ProspectMobilePhone__c}
                    </a>
                  </Td>
                  <Td borderColor='gray.200' borderWidth='1px'>{record.TchAddress__c}</Td>
                  <Td borderColor='gray.200' borderWidth='1px'>{record.OfferName__c}</Td>
                  <Td borderColor='gray.200' borderWidth='1px'>
                    <Tag size="sm" colorScheme={getStatusColorScheme(record.Status__c)} variant="solid">
                      <TagLabel>{record.Status__c}</TagLabel>
                    </Tag>
                  </Td>
                  <Td borderColor='gray.200' borderWidth='1px'>
                    {record.ConnectionStatus__c ? (
                      <Tag size="sm" colorScheme={getStatusColorScheme(record.ConnectionStatus__c)} variant="solid">
                        <TagLabel>{record.ConnectionStatus__c}</TagLabel>
                      </Tag>
                    ) : null}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeaderTable;